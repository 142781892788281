import { Button } from "@/components/ui/button";
import { useNavigate, useLocation } from "react-router-dom";

const NotAuthenticated = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <h1 className="text-center text-3xl font-bold">Not Authenticated</h1>
      <p className="mt-2 text-center text-gray-500">
        Please login to access this page
      </p>
      <Button
        onClick={() => {
          navigate("/login", {
            state: { from: location.pathname },
            replace: true,
          });
        }}
        className="w-40  mt-4"
      >
        Log In
      </Button>
    </div>
  );
};

export default NotAuthenticated;
