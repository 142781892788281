import { toast } from "sonner";
import axiosInstance from "./axios";

export const createConsultant = async (service, docs, agree) => {
  if (agree === true) {
    try {
      const res = await axiosInstance.post("consultation/consultant", {
        service,
        documents: docs,
        agreeToTerms: agree,
      });

      if (res.status === 201) {
        return res.data;
      } else {
        toast.error(res.data.message);
        throw new Error(res.data.message);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      throw new Error(e.response.data.message);
    }
  }
  return false;
};

export const getConsultantsByUserId = async () => {
  try {
    const res = await axiosInstance.get("consultation/consultant");

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const getConsultantsById = async (id) => {
  try {
    const res = await axiosInstance.get(`consultation/consultant/${id}`);

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const getConsultantsByIdParams = async (id) => {
  try {
    const res = await axiosInstance.get(`consultation/consultant/params/${id}`);

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const updateConsultantFeesAndResponseTime = async (
  fees,
  responseTime,
  tags,
  description
) => {
  try {
    const res = await axiosInstance.put("consultation/consultant/fees", {
      fees,
      responseTime,
      tags,
      description
    });

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const updateConsultantStatus = async (id, status, remark) => {
  try {
    const res = await axiosInstance.put(
      "consultation/consultant/status/" + id,
      {
        status,
        remark,
      },
    );

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const getAllConsultants = async () => {
  try {
    const res = await axiosInstance.get("consultation/consultant/all");

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const createPromotionTemplate = async (promotion) => {
  try {
    const res = await axiosInstance.post(
      "consultation/promotion/templete/create",
      {
        title: promotion.title,
        promotions: promotion.promotions,
        note: promotion.note,
      },
    );
    if (res.status === 201) {
      return res.data;
    } else {
      toast.error(res.data);
      throw new Error(res.data);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data);
  }
};

export const editPromotionTemplate = async (id, promotion) => {
  try {
    // console.log(promotion);
    const res = await axiosInstance.put(
      `consultation/promotion/templete/${id}`,
      {
        promotions: promotion.promotions,
        note: promotion.note,
      },
    );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const deletePromotionTemplate = async (id) => {
  try {
    const res = await axiosInstance.delete(
      `consultation/promotion/templete/${id}`,
    );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data);
      throw new Error(res.data);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data);
  }
};

export const getPromotionTemplatesById = async (id) => {
  try {
    const res = await axiosInstance.get(
      `consultation/promotion/templete/${id}`,
    );

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const createAppointmentTemplate = async (template) => {
  try {
    const res = await axiosInstance.post(
      "consultation/appointment/templete/create",
      {
        title: template.title,
        description: template.description,
        appointments: template.appointments,
        note: template.note,
      },
    );
    if (res.status === 201) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const editAppointmentTemplate = async (id, template) => {
  try {
    const res = await axiosInstance.put(
      `consultation/appointment/templete/${id}`,
      {
        description: template.description,
        appointments: template.appointments,
        note: template.note,
      },
    );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const deleteAppointmentTemplate = async (id) => {
  try {
    const res = await axiosInstance.delete(
      `consultation/appointment/templete/${id}`,
    );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data);
      throw new Error(res.data);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data);
  }
};

export const getAppointmentTemplatesById = async (id) => {
  try {
    const res = await axiosInstance.get(
      `consultation/appointment/templete/${id}`,
    );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const getPromotionTemplates = async (id) => {
  try {
    const res = await axiosInstance.get(
      "consultation/promotion/templete/user/" + id,
    );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const getAppointmentTemplates = async (id) => {
  try {
    const res = await axiosInstance.get(
      "consultation/appointment/templete/user/" + id,
    );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const createCoupon = async (code, promotion, appointment) => {
  try {
    const res = await axiosInstance.post("consultation/coupon", {
      code,
      promotion,
      appointment,
    });

    if (res.status === 201) {
      toast.success("Coupon created successfully");
      return res.data;
    } else {
      toast.error(res.data.message);
    }
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.message);
  }
};

export const getCoupons = async () => {
  try {
    const res = await axiosInstance.get("consultation/coupon");

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const getCouponById = async (couponID) => {
  try {
    const res = await axiosInstance.get("consultation/coupon/" + couponID);

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const deleteCoupon = async (couponID) => {
  try {
    const res = await axiosInstance.delete("consultation/coupon", {
      data: { couponID },
    });

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

export const getChatHistory = async (roomId) => {
  try {
    const res = await axiosInstance.get(`consultation/chat/${roomId}`);

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const getChatPermits = async (roomId) => {
  try {
    const res = await axiosInstance.get(`consultation/chat/permit/${roomId}`);

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const changePermitStatus = async (roomId, status) => {
  try {
    const res = await axiosInstance.put("consultation/chat/permit", {
      roomId,
      status,
    });

    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};

export const getChatHistoryOfUser = async (user) => {
  try {
    const res = await axiosInstance.get(`consultation/chat/user/${user}`);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error(res.data);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
};
