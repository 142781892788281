import React, { lazy } from "react";

// Lazy load the ad manager components
const AdManager = lazy(() => import("@/pages/AdManager/AdManager"));
const ManageAdDetails = lazy(() => import("@/pages/AdManager/ManageAdDetails"));

const userAdManagerRoutes = [
  { path: "/ad-manager", element: <AdManager /> },
  { path: "/manage-ad-details", element: <ManageAdDetails /> },
];

export default userAdManagerRoutes;
