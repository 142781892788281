import React, { Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";

import PersistentLogin from "@/components/PersistentLogin";
import Header from "@/components/Header";
import Sidebar from "@/components/Sidebar";
import NotAuthenticated from "@/components/NotAuthenticated";

import authRoutes from "@/Routes/AuthRoutes";
import publicRoutes from "@/Routes/PublicRoutes";
import adminRoutes from "@/Routes/AdminRoutes";
import auctionRoutes from "@/Routes/AuctionRoutes";
import walletRoutes from "@/Routes/WalletRoutes";
import settlementRoutes from "@/Routes/SettlementRoutes";
import chatRoutes from "@/Routes/ChatRoutes";
import profileAndSettingsRoutes from "@/Routes/ProfileAndSettingsRoutes";
import tradingAccountRoutes from "@/Routes/TradingAccountRoutes";
import consultationRoutes from "@/Routes/ConsultationRoutes";
import directSellRoutes from "@/Routes/DirectSRoutes";
import collaborationRoutes from "@/Routes/CollaborationRoutes";

import useAuth from "@/redux/dispatch/useAuth"; // Adjust the import according to your actual auth context file path
import affiliationRoutes from "./Routes/AffiliationRoutes";
import assistsRoutes from "./Routes/AssistsRoute";
import userAdManagerRoutes from "./Routes/UserAdManagerRoutes";
import BiddingHome from "./pages/Home/BiddingHome";
import UnauthorisedBiddingHome from "./pages/Home/UnauthorisedBiddingHome";

import { Navigate, useParams } from "react-router-dom";
import LoadingSpinner from "./components/ui/LoadingSpinner";

const RedirectWithId = () => {
  const { id } = useParams(); // Extracts the dynamic `id` parameter from the URL
  return <Navigate to={`/unauthhome-bidding/${id}`} replace />;
};

const AppRoutes = () => {
  const { isAuthenticated, isAdmin } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Suspense fallback={<LoadingSpinner color="black" />}>
      <Routes>
        {/* Auth Routes */}
        {authRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}

        {!isAuthenticated && (
          <Route path="/home-bidding/:id" element={<RedirectWithId />} />
        )}

        {/* Persistent login wrapper */}
        <Route element={<PersistentLogin />}>
          <Route
            path="*"
            element={
              <>
                <Header toggleSidebar={toggleSidebar} />
                <div className="flex flex-1 overflow-hidden">
                  <Sidebar
                    isOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                  />
                  <div className="flex-1 overflow-auto">
                    <Routes>
                      {/* Public Routes */}
                      {publicRoutes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      ))}

                      {/* Protected Routes */}
                      {isAuthenticated && (
                        <>
                          {collaborationRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {profileAndSettingsRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {auctionRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {userAdManagerRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {tradingAccountRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}

                          {directSellRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}

                          {walletRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {settlementRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {chatRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {consultationRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {affiliationRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                          {assistsRoutes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={route.element}
                            />
                          ))}
                        </>
                      )}
                      {/* {!isAuthenticated && path === "/home-bidding/:id" && (
                      <Route
                        path="/unauthhome-bidding/:id"
                        element={<UnauthorisedBiddingHome />}
                      />
                    )} */}

                      {/* Admin Routes */}
                      {isAuthenticated &&
                        isAdmin &&
                        adminRoutes.map((route, index) => (
                          <Route
                            key={index}
                            path={route.path}
                            element={route.element}
                          />
                        ))}

                      {/* Fallback Route */}
                      {/* <Route path="/admin/*" element={<Navigate to="/admin" />} /> */}
                      <Route path="*" element={<NotAuthenticated />} />
                    </Routes>
                  </div>
                </div>
              </>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
