import { initializeApp } from "firebase/app";
import { initializeAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_GBXh6CFXsQxGjix-vpn_xSF5IS_8In0",
  authDomain: "final-c86fb.firebaseapp.com",
  projectId: "final-c86fb",
  storageBucket: "final-c86fb.appspot.com",
  messagingSenderId: "312456416004",
  appId: "1:312456416004:web:6fc936ad1147f5434f9e51",
  measurementId: "G-RX0T1BPGQ6"
};
const secondaryFirebaseConfig = {
  apiKey: "AIzaSyCsUQO6f5CJlGZpzAvSVXPRcGbvPnnrJec",
  authDomain: "insta-e0ec5.firebaseapp.com",
  projectId: "insta-e0ec5",
  storageBucket: "insta-e0ec5.appspot.com",
  messagingSenderId: "1078099453668",
  appId: "1:1078099453668:web:ffcefd0fb8c57d1e4a2ef1",
  measurementId: "G-15CX4G3B7Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app);
const db = getFirestore(app);
const secondaryApp = initializeApp(secondaryFirebaseConfig, "secondary");
const secondaryDB = getFirestore(secondaryApp);
const storage = getStorage();
export { auth, app, db, secondaryApp, secondaryDB, storage };