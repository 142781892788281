import React from "react";
import { Button } from "../ui/button";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import useAuth from "@/redux/dispatch/useAuth";

const NotificationCard = ({ notification, markAsRead }) => {
  //console.log(notification);
  return (
    <div
      className={`container flex items-center justify-between gap-1 rounded-md py-2 pr-2 ${
        !notification.read ? "bg-secondary" : "border"
      }`}
    >
      <div className="flex flex-col gap-1">
        <h4
          className="cursor-pointer text-base font-bold text-primary hover:underline"
          onClick={() => {
            // Your existing navigation logic
          }}
        >
          {notification.title}
        </h4>
        <p className="text-sm text-gray-500">{notification.message}</p>
        <p className="text-xs text-gray-500">
          {new Date(notification.createdAt).toLocaleString()}
        </p>
      </div>
      {!notification.read && (
        <div>
          <Button
            size="icon"
            variant="outline"
            onClick={() => markAsRead(notification._id)}
          >
            <Check className="text-sm text-primary" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotificationCard;
