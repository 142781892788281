import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { FaPlay } from "react-icons/fa";
import { getStorage, ref, getMetadata } from "firebase/storage";
import Modal from "react-modal";
import { useSwipeable } from "react-swipeable";
import axiosInstance from "@/api/axios"; // Assuming you have your axios instance setup
import { Timestamp } from "firebase/firestore";

// Metadata cache
const metadataCache = new Map();

const AdCarousel = ({ adminAd, defaultImage, defaultUrl, auctionId, userId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState("");

  const intervalDuration = 60 / 12; // in seconds

  // Memoize the images array to prevent recalculating on every render
  const images = useMemo(
    () =>
      Array(12)
        .fill(null)
        .map((_, index) => {
          const adminAdEntry = adminAd.find((ad) => ad.position === index + 1);
          return adminAdEntry
            ? {
                url: adminAdEntry.image,
                link: adminAdEntry.url,
                visibility: adminAdEntry.visibility,
                adId: adminAdEntry.adId, // Include adId for analytics
                position: index + 1, // Include position for analytics
              }
            : { url: defaultImage, link: defaultUrl, visibility: "public" };
        }),
    [adminAd, defaultImage, defaultUrl]
  );

  // Helper function to send analytics data using axios
  const updateAuctionAnalytics = useCallback(
    async (action, adId, position) => {
      try {
        await axiosInstance.patch("/auctions/updateAuctionAnalytics", {
          auctionId,
          adId,
          userId: userId || null, // Optional userId
          action, // 'click' or 'view'
          position,
        });
        // console.log("Auction analytics updated successfully",position);
      } catch (error) {
        console.error("Error updating auction analytics:", error);
      }
    },
    [auctionId, userId]
  );

  // Calculate the initial index based on the current time
  const calculateInitialIndex = useCallback(() => {
    const now = new Date();
    const seconds = now.getSeconds();
    const currentSlot = Math.floor(seconds / intervalDuration);
    return currentSlot % images.length;
  }, [intervalDuration, images.length]);

  useEffect(() => {
    let isMounted = true; // Track if component is mounted

    const fetchAllMetadata = async () => {
      const storage = getStorage();
      const types = await Promise.all(
        images.map(async (imageObj) => {
          const url = imageObj.url;
          if (metadataCache.has(url)) {
            return metadataCache.get(url);
          }
          try {
            const storageRef = ref(storage, url);
            const metadata = await getMetadata(storageRef);
            const contentType = metadata.contentType;
            if (isMounted) {
              metadataCache.set(url, contentType);
            }
            return contentType;
          } catch (error) {
            console.error("Failed to fetch metadata for", url, error);
            return null;
          }
        })
      );
      if (isMounted) {
        setMediaTypes(types);
      }
    };

    fetchAllMetadata();

    // Set the initial index once on mount
    setCurrentIndex(calculateInitialIndex());

    // Set an interval to switch the image at the defined interval
    const interval = setInterval(() => {
      const newIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(newIndex);

      // Trigger view analytics for the current image
      const currentAd = images[newIndex];
      if (currentAd.adId && currentAd.visibility === "public") {
        updateAuctionAnalytics("view", currentAd.adId, currentAd.position);
      }
    }, intervalDuration * 1000);

    return () => {
      isMounted = false; // Set flag to false when unmounting
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [images, intervalDuration, calculateInitialIndex, updateAuctionAnalytics, currentIndex]);

  const openModal = useCallback((url) => {
    setSelectedMediaUrl(url);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedMediaUrl("");
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length),
    onSwipedRight: () => setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="relative mx-auto w-full max-w-full rounded-md shadow-md">
      <div {...handlers} className="relative overflow-hidden rounded-md bg-white">
        {images.map(
          (imageObj, index) =>
            imageObj.visibility === "public" && (
              <div
                key={index}
                className={`transition-opacity duration-300 ${index === currentIndex ? "opacity-100" : "opacity-0"}`}
                style={{
                  position: index === currentIndex ? "relative" : "absolute",
                  width: "100%",
                }}
              >
                <img
                  src={imageObj.url}
                  alt={`Media ${index + 1}`}
                  className="h-full w-full cursor-pointer rounded-md object-contain"
                  style={{ aspectRatio: "4 / 1" }}
                  onClick={() => {
                    if (imageObj.link) {
                      // Open the URL in a new tab
                      const urlToOpen = imageObj.link.startsWith("www.") ? `https://${imageObj.link}` : imageObj.link;
                      window.open(urlToOpen, "_blank");

                      // Trigger click analytics
                      updateAuctionAnalytics("click", imageObj.adId, imageObj.position);
                    }
                  }}
                />
                <span className="p-1 pl-1.5 z-10 text-xs absolute top-0 left-0 font-medium">Ad</span>
              </div>
            )
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Media Modal"
        className="flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
      >
        <div className="relative flex w-fit items-center justify-center rounded-md p-4 shadow-lg sm:h-[70vh] sm:w-[70vw] md:h-[70vh] md:w-[60vw] lg:h-[70vh] lg:w-[75vw] xl:h-[70vh] xl:w-fit">
          <button
            onClick={closeModal}
            className="bg-black-900 absolute -right-0 -top-0 h-8 w-8 rounded-full text-2xl text-white"
          >
            &times;
          </button>
          <img src={selectedMediaUrl} alt="Selected Media" className="h-full w-full rounded-md object-contain p-2" />
        </div>
      </Modal>
    </div>
  );
};

export default AdCarousel;
