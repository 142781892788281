import React, { lazy } from "react";

// Lazy load the trading account component
const TradingAccount = lazy(() => import("@/pages/Transaction/TradingAccount"));

const tradingAccountRoutes = [
  { path: "/trading", element: <TradingAccount /> },
];

export default tradingAccountRoutes;
