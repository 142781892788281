import React, { lazy } from "react";

// Lazy load the affiliation center components
const AffiliationCenter = lazy(
  () => import("@/pages/AffiliationCenter/AffiliationCenter"),
);
const ParticularInsights = lazy(
  () => import("@/pages/AffiliationCenter/ParticularInsights"),
);

const affiliationRoutes = [
  { path: "/affiliation-center", element: <AffiliationCenter /> },
  { path: "/affiliation-center/insights/:id", element: <ParticularInsights /> },
];

export default affiliationRoutes;
