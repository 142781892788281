import React, { useEffect, useState, useRef } from 'react';
import DiscreteSlider from './DiscreteSlider';
import axiosInstance from '@/api/axios';
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { FaReply, FaPaperPlane} from "react-icons/fa6";


const Popup = ({
  sender,
  message,
  setMessage,
  chars,
  coins,
  setCoins,
  setChars,
  handleSend,
  availableCoins,
  coinUrl
}) => {
  const [charValues, setCharValues] = useState([]);
  const [coinValues, setCoinValues] = useState([]);
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const fetchCoinValues = async () => {
    try {
      const response = await axiosInstance.get(coinUrl);
      if (response.data.status === "ok") {
        const coinsArray = response.data.coinArr.map(item => item.multipliedCoins);
        const charsArray = response.data.coinArr.map(item => item.characters);
        setCoinValues(coinsArray);
        setCharValues(charsArray);
        return true;
      } else if (response.data.status === "disabled") {
        toast.error("The receiver has their Nob chat settings disabled");
      } else if (response.data.status === "failed") {
        toast.error("The receiver hasn't set their Nob chat settings");
      } else {
        console.error(response.data.message);
        toast.error("Error fetching Nob chat settings");
      }
      return false;
    } catch (error) {
      console.error("Error fetching coin values:", error);
    }
  };

  const handleOpenNobChat = async() => {
    setPopup(await fetchCoinValues());
  }

  return (
    <div className="inline-block w-full rounded-lg ">
      {!popup ? (
        <>
          <div className="inline-block p-2 rounded-lg shadow-lg w-full">
            <div className="flow-root">
              <div className="float-left text-s text-gray-500 mb-4 opacity-75 ">@{sender.userName}</div>
            </div>
            <button
              onClick={async () => await handleOpenNobChat()}
              className="w-full p-2 bg-gray-700 flex items-center justify-center text-white rounded shadow-md transition-transform duration-300 ease-in-out hover:transform hover:-translate-y-1 hover:shadow-xl"
            >
              <FaPaperPlane/><span className="pl-2">Send Message</span>
            </button>
          </div>
        </>
      ) : (
        <>  
          <div className="flex text-gray-800 opacity-75 font-medium p-2 mb-4 border-y border-primary">
            <button
              onClick={() => setPopup(false)} 
            >
              X
            </button>
            <div className="flex-1 text-center">Send Message</div>
            <div>
              <button
              
                onClick={() => navigate("/support/faq-home")} 
              >
                ?
              </button>
              
            </div>
          </div>
          <div className="inline-block p-2 w-full rounded-lg border shadow-md transition-transform duration-300 ease-in-out hover:transform hover:-translate-y-1 hover:shadow-md">
            <div className="flow-root">
              <div className="float-left text-s text-gray-500 mb-4 opacity-75 ">@{sender.userName}</div>
              <div className="float-right text-s font-medium text-gray-500 mb-4 opacity-75 ">{message.length}/{chars}</div>
            </div>
            <textarea
              value={message}
              rows="1"
              onChange={(e) => {setMessage(e.target.value);
                e.target.style.height = "auto";
                e.target.style.height = (e.target.scrollHeight) + "px"}
              }
              className="w-full p-2 border-solid rounded-md border-2 resize-none overflow-auto h-auto max-h-64"
              placeholder="Type your message"
              maxLength={charValues[charValues.length - 1]}
            />
          </div>
          <div className='flex flex-col items-center'>       
            <div className="my-4 px-3 p-0.5 w-fit text-center font-bold rounded border-solid border-2">
              {coins} N
            </div>
            <DiscreteSlider
              charValues={charValues}
              coinValues={coinValues}
              onCoinChange={(value) => setCoins(value)}
              onCharChange={(value) => setChars(value)}
              currentTextLength={message.length}
            />
            <button onClick={handleSend} className="p-2 px-3 mt-4 bg-gray-700 text-white rounded shadow-md transition-transform duration-300 ease-in-out hover:transform hover:-translate-y-1 hover:shadow-xl">
              <span>Use </span><span className="font-bold text-xl"> {coins} </span><span> Nob Coins and Send</span>
            </button>
          </div>
          <div className="text-center m-2">Nob Coins balance: <span className="text-center text-lg font-bold">{availableCoins} N</span></div>
          <div className="text-center">
            <Button
                className="mb-2  w-fit rounded-lg px-4 py-3 font-semibold shadow-md"
                variant="outline"
                onClick={() => navigate("/wallet")}
              >
                Buy Nob Coins
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Popup;
