import React, { useEffect, useState, useRef } from 'react';
import { format } from "date-fns";
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { useNavigate } from "react-router";
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { MdOutlineReport } from 'react-icons/md';
import { toast } from "sonner";
import Popup from './Popup';
import DiscreteSlider from './DiscreteSlider';
import axiosInstance from '@/api/axios';
import useAuth from '@/redux/dispatch/useAuth';
//import MiniProfile from "@/components/Profile/MiniProfile";

const LiveChatBox = ({ auction }) => { 
  const [messages, setMessages] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [coins, setCoins] = useState(5);
  const [chars, setChars] = useState(50);
  const [availableCoins, setAvailableCoins] = useState(0);
  const [sender, setSender] = useState({});

  const { auth, socket } = useAuth();
  const id = auth?.user?.id;
  const chatEndRef = useRef(null);
  const navigate = useNavigate();

  const fetchUserData = async () => {
    try {
      const res = await axiosInstance.get(`/user/getUser/${auth.user.id}`);
      setSender(res.data.user);
    } catch (error) {
      console.error(error);
      alert("Error in getting user data");
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await axiosInstance.post("wallet/balance", {});
      setAvailableCoins(response.data.balance);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const fetchBlockedUsers = async () => {
    try {
      const response = await axiosInstance.get(`user/getHostBlockuserDetails/${auction.HostId}`);
      setBlockedUsers(response.data);
    } catch (error) {
      console.error("Error fetching blocked users:", error);
    }
  };

  useEffect(() => {
    if (id) {
        fetchBalance();
        fetchUserData();
        fetchBlockedUsers();
    }
  }, [id, auction._id, messages]);

  useEffect(() => {

    const fetchMessages = async () => {
      const response = await axiosInstance.post(`chat/live/messages/`, { auctionId: auction._id });
      setMessages(response.data);
    };  
    fetchMessages();

    if (id) {
        socket.emit('joinAuctionRoom', { auctionId: auction._id, userId: id });
    
        socket.on('receiveMessageToAlL', (data) => {
          setMessages((prevMessages) => [...prevMessages, data]);
          scrollToBottom();
        });
    
        socket.on('errorMessage', (msg) => {
          console.error(msg);
        });
    }
  }, [id, auction._id]);

  const handleSend = async () => {
    if (message.trim()) {
      if (availableCoins >= coins) {
        socket.emit('sendMessageToAlL', {
          sender: sender._id,
          auctionId: auction._id,
          content: message,
          coins: coins
        });
        setMessage('');

        try {
          const response = await axiosInstance.post('wallet/balance', {});
          setAvailableCoins(response.data.balance);
        } catch (error) {
          console.error('Error fetching balance:', error);
        }
      } else {
        toast.error('Insufficient Nob Coins to send the message');
      }
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const formatDate = (date) => {
    date = new Date(date).toDateString();
    const options = { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    };
    const today = new Date();
    const goBackDays = (n) => today.setDate(new Date().getDate() - n);
    
    switch (date) {
      case new Date(goBackDays(0)).toDateString():
        return 'Today';
      case new Date(goBackDays(1)).toDateString():
        return 'Yesterday';
      default:
        break;
    }

    for(let i = 2; i < 7; i++){
      if(date === new Date(goBackDays(i)).toDateString()) return new Date(date).toLocaleDateString(undefined, { weekday: 'long'});
    }

    return new Date(date).toLocaleDateString(undefined, options);
  };

  const renderMessages = () => {
    const groupedMessages = {};

    const handleLike = async (msg) => {
      try {
        const res = await axiosInstance.post(`/chat/live/like`, { messageId: msg._id });
        setMessages(res.data);
      } catch (error) {
        console.error("Error liking the message", error);
      }
    };

    messages.forEach(message => {
      let date = new Date(message.sendTime).toDateString();
      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(message);
    });

    const sortedDates = Object.keys(groupedMessages).sort((a, b) => new Date(a) - new Date(b));

    return sortedDates.map(date => (
      <div key={date}>
        <div className="date-header text-xs text-center opacity-75 m-4">
          {formatDate(date)}
        </div>
        {groupedMessages[date].map((msg, index) => (
          <div key={index} className={`mb-1  ${msg.sender === sender._id ? 'mr-1 grid place-content-end' : 'grid place-content-start'}`}>
            <div className={`inline-block flex grid grid-cols grid-rows w-fit  ${msg.sender === sender._id ? 'rounded-l-lg rounded-tr-lg pl-2 pr-2 pb-2  bg-zinc-100 text-black text-left ' : 'pl-2 pr-2 pb-2  rounded-r-lg rounded-tl-lg bg-zinc-100 text-left text-black'}`}>
              <div className='flex items-center place-content-start gap-1'>
                <button 
                  className={`text-[0.85rem]  text-left font-medium ${msg.sender === sender._id ? 'text-gray-600' : 'text-gray-600'}`}
                  onClick={() => {
                          if (id) {
                            navigate(`/profile/${msg.userName}`);
                          } else {
                            navigate("/login");
                          }
                        }}
                >
                  @{msg.userName} 
                </button>
              
                
                    <button 
                      className="  "
                      onClick={() => {
                          if (id) {
                            navigate("/support");
                          } else {
                            navigate("/login");
                          }
                        }}
                    >
                      <MdOutlineReport size={15} className="hover:text-white-900  hover:opacity-100 transition opacity-50 duration-300 ease-in-out" />
                    </button>
                
              </div>
              <div className='text-left flex font-normal text-md break-words gap-3 '>
                <span className='' style={{ whiteSpace: "pre-line" }}>{msg.content} </span>
                
                
                
              </div>
              <div className={`flex  items-end ${msg.sender === sender._id ? 'justify-end' : 'justify-end'}`}>
                  <button onClick={() => {if (id) handleLike(msg)}} className="mr-1">
                    {msg.likes?.includes(id) && id !== null ? <AiFillLike size={16} /> : <AiOutlineLike size={16} />}
                  </button>
                  <span className='text-xs mr-1'>{msg.likes?.length > 0 ? msg.likes?.length : ''}</span>
              </div>
               
            </div> 
            <div >
              
              <div>
                
              </div>
            </div>         
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className="p-0 flex flex-col h-full">
    {/* <MiniProfile userId={auction.HostId}/> */}
      <div className="flex justify-between items-center mb-2">
        {/*<button onClick={() => setSelectedChat(null)} className="p-2 bg-black text-white rounded">Back</button>*/}
      </div>
      <ScrollArea.Root className="pl-1 flex-grow overflow-y-auto mb-4 max-h-96">
        <ScrollArea.Viewport>
          {renderMessages()}
          <div ref={chatEndRef} />
        </ScrollArea.Viewport>
      </ScrollArea.Root> 
      {!id ? (
          <div className="text-center text-red-500">Login to message</div>
        ) : auction.NobChat !== 'Enable' ? (
          <div className="text-center text-gray-500">Chat is disabled</div>
        ) : blockedUsers.includes(id) ? (
          <div className="text-center text-red-500">Chat is disabled for you</div>
        ) : (
          <Popup
            sender={sender}
            message={message}
            setMessage={setMessage}
            chars={chars}
            coins={coins}
            setCoins={setCoins}
            setChars={setChars}
            handleSend={handleSend}
            availableCoins={availableCoins}
            coinUrl={`livesettings/coins?userId=${auction.HostId}`}
          />
        )}
    </div>
  );
};

export default LiveChatBox;