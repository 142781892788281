import ConsultationChatBox from "@/pages/Consultation/Chat/ChatBox";
import React, { lazy } from "react";

// Lazy load the consultation components
const ConsultationPromotionTemplate = lazy(
  () => import("@/pages/Consultation/Templetes/ConsultationPromotionTemplate"),
);
const ConsultationAppointmentTemplate = lazy(
  () => import("@/pages/Consultation/Templetes/CreateAppointmentTemplate"),
);
const ConsultationManagerPage = lazy(
  () => import("@/pages/Consultation/ConsultationManagerPage"),
);
const ConsultationPage = lazy(
  () => import("@/pages/Consultation/ConsultationPage"),
);
const ConsultationPromotionTemplateEdit = lazy(
  () => import("@/pages/Consultation/Templetes/PromotionTemplateEdit"),
);
const ConsultationAppointmentTemplateEdit = lazy(
  () => import("@/pages/Consultation/Templetes/AppointmentTemplateEdit"),
);
const CreateCouponTemplatePage = lazy(
  () => import("@/pages/Consultation/Templetes/CreateCouponTemplate"),
);
const ConsultationRequestsPage = lazy(
  () => import("@/pages/Consultation/Request/ConsultationRequestsPage"),
);
const PromotionsRequests = lazy(
  () => import("@/pages/Consultation/Request/PromotionRequestsByCart"),
);
const OrderDetails = lazy(() => import("@/components/AuctionCenter/Order"));
const PromotionRequestsDetail = lazy(
  () => import("@/pages/Consultation/Request/PromotionRequestsDetailsByCart"),
);
const AppointmentRequests = lazy(
  () => import("@/pages/Consultation/Request/AppointmentRequestByCart"),
);
const AppointmentRequestsDetail = lazy(
  () => import("@/pages/Consultation/Request/AppointmentRequestDetailsByCart"),
);

const ConsultationOrder = lazy(
  () => import("@/pages/Consultation/Orders/ConsultationOrderPage"),
);
const AppointmentOrder = lazy(
  () => import("@/pages/Consultation/Orders/AppointmentOrder"),
);
const PromotionOrder = lazy(
  () => import("@/pages/Consultation/Orders/PromotionOrder"),
);

const AppointmentOrderDetail = lazy(
  () => import("@/pages/Consultation/Orders/AppointmentOrderDetail"),
);

const PromotionOrderDetail = lazy(
  () => import("@/pages/Consultation/Orders/PromotionOrderDetail"),
);
const Consult = lazy(() => import("@/pages/Consultation/ConsultAssistMain"));

const ConsultationChat = lazy(
  () => import("@/pages/Consultation/Chat/ConsultationChat"),
);

const consultationRoutes = [
  {
    path: "/settings/consultation-manager",
    element: <ConsultationManagerPage />,
  },
  { path: "/consultation", element: <ConsultationPage /> },
  {
    path: "/consultation/promotion/templete",
    element: <ConsultationPromotionTemplate />,
  },
  {
    path: "/consultation/appointment/templete",
    element: <ConsultationAppointmentTemplate />,
  },
  {
    path: "/consultation/promotion/templete/:id",
    element: <ConsultationPromotionTemplateEdit />,
  },
  {
    path: "/consultation/appointment/templete/:id",
    element: <ConsultationAppointmentTemplateEdit />,
  },
  {
    path: "/consultation/coupon/template",
    element: <CreateCouponTemplatePage />,
  },
  {
    path: "/consultation/requests",
    element: <ConsultationRequestsPage />,
  },
  {
    path: "/consultation/requests/promotions",
    element: <PromotionsRequests />,
  },
  {
    path: "/consultation/requests/promotions/:id",
    element: <PromotionRequestsDetail />,
  },
  {
    path: "/settlements-center/consultation/:id",
    element: <OrderDetails />,
  },
  {
    path: "/consultation/requests/appointments",
    element: <AppointmentRequests />,
  },
  {
    path: "/consultation/requests/appointments/:id",
    element: <AppointmentRequestsDetail />,
  },
  {
    path: "/consultation/orders",
    element: <ConsultationOrder />,
  },
  {
    path: "/consultation/orders/appointments",
    element: <AppointmentOrder />,
  },
  {
    path: "/consultation/orders/promotions",
    element: <PromotionOrder />,
  },
  {
    path: "/consultation/orders/appointments/:id",
    element: <AppointmentOrderDetail />,
  },
  {
    path: "/consultation/orders/promotions/:id",
    element: <PromotionOrderDetail />,
  },
  {
    path: "/consult/:id",
    element: <Consult />,
  },
  {
    path: "/consultation/chat",
    element: <ConsultationChatBox />,
  },
  {
    path: "/consultation/chat/:id",
    element: <ConsultationChat />,
  },
];

export default consultationRoutes;
