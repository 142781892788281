import React, { lazy } from "react";

// Lazy load the settlements center components
const SettleMents = lazy(
  () => import("@/pages/SettlementsCenter/SettleMentsCenter"),
);
const SAuction = lazy(() => import("@/pages/SettlementsCenter/SAuction"));
const SBid = lazy(() => import("@/pages/SettlementsCenter/SBid"));
const ParticularBr = lazy(
  () => import("@/pages/SettlementsCenter/ParticularBr"),
);
const Earning = lazy(() => import("@/pages/Earning/Earning"));

const settlementRoutes = [
  { path: "/settlements-center", element: <SettleMents /> },
  { path: "/settlements-center/:id", element: <SAuction /> },
  { path: "/settlements-center/bids/:id", element: <SBid /> },
  { path: "/settlements-center/br/:id", element: <ParticularBr /> },
  { path: "/earnings", element: <Earning /> }
];

export default settlementRoutes;
