import React, { lazy } from "react";

// Lazy load the direct sell components
const DirectSell = lazy(() => import("@/pages/DirectSell/DirectSell"));
const ProductAdd = lazy(() => import("@/pages/DirectSell/ProductAdd"));
const ProductUpdate = lazy(() => import("@/pages/DirectSell/ProductUpdate"));
const Inventory = lazy(() => import("@/components/DirectSell/Inventory"));
const ManagePage = lazy(() => import("@/pages/DirectSell/ManagePage"));
const OrdersPage = lazy(() => import("@/components/DirectSell/OrdersPage"));
const ManageProduct = lazy(
  () => import("@/components/DirectSell/ManageProduct"),
);

const directSellRoutes = [
  { path: "/direct-sell", element: <DirectSell /> },
  { path: "/direct-sell/addproduct", element: <ProductAdd /> },

  { path: "/direct-sell/manageallproduct", element: <ManageProduct /> },
  { path: "/direct-sell/manageproduct/:productId", element: <ManagePage /> },

  { path: "/direct-sell/inventory", element: <Inventory /> },
  {
    path: "/direct-sell/manageinventory/:productId",
    element: <ProductUpdate />,
  },

  { path: "/direct-sell/orders", element: <OrdersPage /> },
];

export default directSellRoutes;
