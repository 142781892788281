import React, { memo, useEffect, useRef, useState } from "react";
import BiddersCard from "./BiddersCard";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { MdVerifiedUser } from "react-icons/md";

import useAuth from "@/redux/dispatch/useAuth";
import axiosInstance from "@/api/axios";
import { toast } from "sonner";
import NextBid from "@/components/HomePage/NextBid";
import LiveChatBox from "@/components/Chat/LiveChatBox";
import { IoHeartOutline, IoShareSocialOutline } from "react-icons/io5";
import { IoHeart } from "react-icons/io5";
import clipText from "@/lib/getClipText";
import { IoEyeOutline } from "react-icons/io5";
import { IoChatbubbleOutline } from "react-icons/io5";
import { RiAuctionLine } from "react-icons/ri";
import { Button } from "@/components/ui/button";
import { FaMinus, FaPlus,FaMapLocationDot,FaRegFile } from "react-icons/fa6";
import Carousel from "@/components/ui/carousel";
// import { updateAuctionMetrics } from "@/api/auctionsApi";
import profile from "../../assets/profile.png";
import AdCarousel from "@/components/ui/adcarousel";
import { Helmet } from "react-helmet";
import {fetchKYCStatusAndType } from "@/api/kycApi";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
// timer component
const Timer = memo(({ startDate, endDate }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = startDate > now ? startDate - now : endDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTimeLeft(
        `${days > 0 ? `${days}d ` : ""} ${hours > 0 ? `${hours}h ` : ""} ${
          minutes > 0 ? `${minutes}m ` : ""
        } ${seconds}s`,
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [startDate, endDate]);

  if (new Date() < startDate) {
    return (
      <div className="bg-gray-200  rounded-t-lg p-2">
        <h3 className="text-md text-gray-500 md:text-lg xl:text-xl">
          Starts in <span className="font-bold">{timeLeft}</span>
        </h3>
      </div>
    );
  } else if (new Date() > startDate && new Date() < endDate) {
    return (
      <div className="bg-gray-200 rounded-t-lg p-2">
        <h3 className="text-md md:text-lg xl:text-xl">
          Ends in <span className="font-bold">{timeLeft}</span>
        </h3>
      </div>
    );
  } else {
    return (
      <div className="bg-gray-200 rounded-t-lg p-2">
        <h3 className="text-md font-bold md:text-lg xl:text-xl">Closed</h3>
      </div>
    );
  }
});
const BiddingHome = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { id: auctionId } = useParams();



  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isTopBiddersOpen, setIsTopBiddersOpen] = useState(!isMobile);
  const [isBidOpen, setIsBidOpen] = useState(!isMobile);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(!isMobile);
  const [isAnalyticsopen, setisAnalyticsopen] = useState(!isMobile);
  const [isAnyOpen, setIsAnyOpen] = useState(false);
  const [isBiddersopen, setisbiddersOpen] = useState(!isMobile);
  const [balance, setBalance] = useState(0);
  const [auction, setAuction] = useState(null);
  const [topBidders, setTopBidders] = useState([]);
  const [bidAmount, setBidAmount] = useState();
  const [auctionQty, setAuctionQty] = useState(0);
  const [nextBid, setNextBid] = useState(0);
  const [winningbid, setwinningbid] = useState(false);
  const [isregistered, setisregistered] = useState(false);
  const [bidQuantity, setBidquantity] = useState(1);
  const [allocatedQuant, setalloactedQuant] = useState(0);
  const [error, seterror] = useState(false);
  const [errormsg, seterrormsg] = useState("");
  const { socket } = useAuth();
  const [shownextBid, setshownextBid] = useState(true);
  const [timeLeft, setTimeLeft] = useState("");
  const [analytics, setAnalytics] = useState(null);
  const startDate = new Date(auction?.StartDate);
  const endDate = new Date(auction?.EndDate);
  const [loading, setloading] = useState(false);
  const [adsbanner, setadsBanner] = useState([]);
  const [blockedUsers, setblockedUsers] = useState(null);
  // const [metrics, setMetrics] = useState(1000);
  const [images, setImages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [posts, setPosts] = useState(0);
  const [isFollowing, setIsFollowing] = useState(false);
  const [collabs, setCollabs] = useState([]);
  const [kycStatus, setKycStatus] = useState("");
  // 1000 is the default value
  const [showpopup, setshowpopup] = useState(false);
  const [Bidderreg, setbidderreg] = useState([]);
  const [currBidderregData, setcurrBidderregData] = useState(null);
  const [userisAllowed, setuserIsallowed] = useState(false);
  const hasCalledRefferedUser = useRef(false);
  const hasCalledanalytics = useRef(false);
  const hasCalledblockedusers = useRef(false);
  const hasImageloaded = useRef(false);
  const query = useQuery();
  const ref = query.get("ref");
  const refId = ref ? ref.substring(ref.indexOf("-") + 1) : null;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const now = new Date();
  //     const distance = startDate > now ? startDate - now : endDate - now;
  //     const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     const hours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  //     );
  //     const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  //     setTimeLeft(
  //       `${days > 0 ? `${days}d ` : ""} ${hours > 0 ? `${hours}h ` : ""} ${
  //         minutes > 0 ? `${minutes}m ` : ""
  //       } ${seconds}s`,
  //     );
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [auction, topBidders]);
  const toggleDiv = () => {
    setIsOpen(!isOpen);
  };

  const toggleTopBidders = () => {
    setIsTopBiddersOpen(!isTopBiddersOpen);
  };
  const toggleAnalytics = () => {
    setisAnalyticsopen(!isAnalyticsopen);
  };

  const toggleBid = () => {
    setIsBidOpen(!isBidOpen);
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };
  const toggleBidders = () => {
    setisbiddersOpen(!isBiddersopen);
  };

  const winning = (topbidders, id) => {
    return topbidders.find((bid) => bid.userID === id);
  };
  const getCollabDetails = async () => {
    try {
      const res = await axiosInstance.get(
        `/auctions/fetchCollabUsers/${auctionId}`,
      );
      setCollabs(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await axiosInstance.post(
        `/payment/balance`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        },
      );
      setBalance(response.data.balance);
    } catch (error) {
      console.error(error);
      // alert("Error in fetching balance");
    }
  };

  const getKycStatus = async () => {
    try {
      const kycData = await fetchKYCStatusAndType(auth.user.id);
        setKycStatus(kycData?.kycStatus);

    }catch (error) {
      console.log(error);
    
  }}
  useEffect(() => {
    getKycStatus();
    getCollabDetails();
  }, []);

  const getAuctionDetails = async () => {
    try {
      const res = await axiosInstance.get(`/auctions/${auctionId}`);
      setAuction(res.data);
      setAuctionQty(res.data.Quantity);
      setBidAmount(res.data.reserved_price);
      setIsRegistered(res.data.Users.includes(auth.user.id));
      setuserIsallowed(res.data.allowedUsers.includes(auth.user.id));
      if (auth.user.id === res.data.HostId || auth.user.isAdmin === true) {
        setuserIsallowed(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (auction) {
      if (auction.visibility === "private") {
        if (!userisAllowed) {
          navigate(`/home-bidding/notallowed/${auctionId}`);
        }
      }
    }
  }, [auction]);
  const showNextBidornot = (topBidders, auction) => {
    let qty = 0;
    for (const b of topBidders) {
      qty += b.Quantity;
    }
    if (qty >= auction?.Quantity) {
      return true;
    } else {
      return false;
    }
  };
  // const getauctionDetails = async () => {
  //   try {
  //     await axiosInstance.get(`/auctions/${auctionId}`).then((res) => {
  //       setAuction(res.data);
  //       setAuctionQty(res.data.Quantity);
  //       setBidAmount(res.data.reserved_price);
  //       setIsRegistered(res.data.Users.includes(auth.user.id));
  //       setuserIsallowed(res.data.allowedUsers.includes(auth.user.id));
  //       if (auth.user.id === res.data.HostId || auth.user.isAdmin === true) {
  //         setuserIsallowed(true);
  //       }
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     alert("error in getting auction details");
  //   }
  // };
  const gettopBidders = async () => {
    try {
      await axiosInstance.get(`/bids/topbidders/${auctionId}`).then((res) => {
        setTopBidders(res.data);
        if (winning(res.data, auth.user.id)) {
          setwinningbid(true);
        }
      });
    } catch (error) {
      console.error(error);
      alert("error in getting top bidders");
    }
  };
  const getallocatedQuant = async () => {
    await axiosInstance
      .get(`/bids/allocatedQuant/${auctionId}`, {
        userID: auth.user.id,
      })
      .then((res) => {
        setalloactedQuant(res.data);
      });
  };
  const updateReferralBid = async (id, refc) => {
    try {
      await axiosInstance
        .post(`/referral/bidReferral/${refc}`, {
          bid: id,
        })
        .then((res) => {
          // console.log(res.data);
        });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };
  const referralValidation = async () => {
    try {
      const response = await axiosInstance.get(
        `/referral/referralValidation/${refId}`,
      );
      // console.log(response.data);
      return true;
    } catch (error) {
      console.log(error);
      setloading(false);
      return false;
    }
  };
  const updatereferreduser = async () => {
    try {
      await axiosInstance
        .post(`/auctions/updateReferredUsers/${auctionId}`, {
          refCode: refId,
        })
        .then((res) => {
          // console.log(res.data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const isReferredUserExist = (ReferredUsers, userid) => {
    return ReferredUsers.find(
      (user) => user.UserId.toString() === userid.toString(),
    );
  };
  const placeBid = async () => {
    try {
      if (checkifuserinblockList(blockedUsers, auth.user.id)) {
        toast.error("Host has blocked you");
        return;
      }
      setloading(true);

      let isReferralValid = true;

      if (ref != null && refId != null) {
        isReferralValid = await referralValidation();
      }

      if (isReferralValid) {
        // console.log(bidAmount, typeof(Number(bidAmount)))
        await axiosInstance
          .post(`/bids/${auctionId}`, {
            amount: Number(bidAmount),
            userId: auth.user.id,
            auctionQty,
            Quantity: bidQuantity,
          })
          .then((res) => {
            // console.log(auction.ReferredUsers);
            const referreduser = isReferredUserExist(
              auction.ReferredUsers,
              auth.user.id,
            );
            // console.log(referreduser);
            if ((ref != null && refId != null) || referreduser) {
              const refc = refId || referreduser.refCode;
              updateReferralBid(res.data._id, refc);
            }
          });
        fetchBalance();
        gettopBidders();

        // Update auction state with new current price
        setAuction((prevAuction) => ({
          ...prevAuction,
          current_price: bidAmount,
        }));

        // Calculate and set the next bid
        setNextBid(
          calculateNextBid([...topBidders, { bidAmount }], auctionQty),
        );
        // getauctionDetails();
        getallocatedQuant();
        setloading(false);
        getAnalytics();
        setshowpopup(false);
        toast.success("Bid placed successfully");
      } else {
        setloading(false);
        toast.error("Referral is not valid. Bid not placed.");
      }
    } catch (error) {
      console.log(error);
      seterror(true);
      setloading(false);
      seterrormsg(error.response.data);
    }
  };

  const calculateNextBid = (topBidders, qty) => {
    const len = topBidders.length;
    if (len < qty) {
      return topBidders[len - 1]?.bidAmount;
    } else {
      const t = topBidders[qty - 1]?.bidAmount;
      return t + (t * 10) / 100;
    }
  };
  // ANAlytics api calls
  const getAnalytics = async () => {
    try {
      await axiosInstance.get(`/analytics/${auctionId}`).then((res) => {
        setAnalytics(res.data);
      });
    } catch (error) {
      console.log(error);
      alert("error in fetching analytics");
    }
  };
  const like = async () => {
    try {
      await axiosInstance.post(`/analytics/like/${auctionId}`).then((res) => {
        // console.log("succesfully liked");
      });
      getAnalytics();
    } catch (error) {
      console.log(error);
      alert("error in like or dislike");
    }
  };
  const share = async () => {
    try {
      await axiosInstance.post(`/analytics/share/${auctionId}`).then((res) => {
        // console.log("succesfully shared");
      });
      getAnalytics();
    } catch (error) {
      console.log(error);
      alert("error in share");
    }
  };
  const views = async () => {
    try {
      await axiosInstance.post(`/analytics/views/${auctionId}`).then((res) => {
        //console.log("viewed succefully");
      });
      getAnalytics();
    } catch (error) {
      console.log(error);
      alert("error in views");
    }
  };
  // const copyUrlToClipboard = () => {
  //   const url = window.location.href;
  //   navigator.clipboard
  //     .writeText(url)
  //     .then(() => {
  //       toast.success("Link Copied");
  //     })
  //     .catch((err) => {
  //       console.error("Failed to copy: ", err);
  //     });
  // };
  const copyUrlToClipboard = () => {
    // Extract the path after the hash (#) from the current URL
    const hashPath = window.location.hash; // Example: #/auction-history/66c07fdb61d0e8ff0efc9bb5

    // Split the hashPath to get the ID (assuming it's the last segment)
    const id = hashPath.split("/").pop();

    // Construct the desired URL using the extracted ID
    const url = `${window.location.origin}/#/unauthhome-bidding/${id}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Link Copied");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  function formatCount(count) {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }
  //  Referral visit count
  const referralVisit = async (refc) => {
    try {
      await axiosInstance.post(`/referral/views/${refc}`).then((res) => {
        // console.log("Referral visits updated succesfully");
      });
    } catch (error) {
      console.log(error);
    }
  };
  // Ads Banner
  const getadsBanner = async (req, res) => {
    try {
      await axiosInstance.get("/ads/secondLatest").then((res) => {
        setadsBanner(res.data);
        //console.log(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserData = async () => {
    try {
      await axiosInstance.get(`/user/getUser/${auction.HostId}`).then((res) => {
        // console.log(res.data);
        setUserData(res.data);
      });
    } catch (error) {
      console.log(error);
      // alert("Error in getting user data");
    }
  };
  const getAuctions = async () => {
    try {
      if (auth && auth.user) {
        const res = await axiosInstance.get(
          `/auctions/user/${auction?.HostId}`,
        );
        // console.log(res.data);
        setPosts(res.data.length);
      } else {
        // console.log("No user data available");
      }
    } catch (error) {
      console.log(error);
      // alert("Error in getting auctions");
    }
  };
  const fetchStats = async () => {
    try {
      const res = await axiosInstance.get(`/profile/stats/${auction?.HostId}`);
      setFollowers(res.data.followers);
      setFollowing(res.data.following);
    } catch (error) {
      console.log(error);
      // alert("Error in getting stats");
    }
  };

  const checkIfFollowing = async () => {
    try {
      const res = await axiosInstance.get(
        `/profile/isFollowing/${auth.user.id}/${auction?.HostId}`,
      );
      setIsFollowing(res.data.isFollowing);
    } catch (error) {
      console.log(error);
      // alert("Error in checking follow status");
    }
  };
  const handleFollow = async () => {
    try {
      await axiosInstance.post(`/profile/follow`, {
        followerId: auth.user.id,
        followingId: auction.HostId,
      });
      setIsFollowing(true);
      setFollowers((prev) => prev + 1);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
      // alert("Error in following user");
    }
  };

  const handleUnfollow = async () => {
    try {
      await axiosInstance.post(`/profile/unfollow`, {
        followerId: auth.user.id,
        followingId: auction.HostId,
      });
      setIsFollowing(false);
      setFollowers((prev) => prev - 1);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
      // alert("Error in unfollowing user");
    }
  };

  useEffect(() => {
    if (auction?.HostId) {
      fetchUserData();
    }
  }, [auction]);
  useEffect(() => {
    if (auction?.HostId) {
      fetchStats();
      getAuctions();
      checkIfFollowing();
    }
  }, [auction]);
  // HOST DETAILS ---------------

  // ---------------------------------
  const handleBiddingSummaryClick = () => {
    navigate("/center", { state: { activeComponent: "BiddingHistory" } });
  };

  //blocked user
  const getHostBlockedusersDetails = async () => {
    try {
      await axiosInstance
        .get(`/user/getHostBlockuserDetails/${auction?.HostId}`)
        .then((res) => {
          //console.log(res.data);
          setblockedUsers(res.data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const checkifuserinblockList = (Users, id) => {
    return Users.includes(id);
  };
  useEffect(() => {
    if (auction && !hasCalledblockedusers.current) {
      getHostBlockedusersDetails();
      hasCalledblockedusers.current = true;
    }
  }, [auction]);
  useEffect(() => {
    if (auction && !hasImageloaded.current) {
      setImages(auction.imageURL);
      hasImageloaded.current = true;
    }
  }, [auction]);
  useEffect(() => {
    setshownextBid(showNextBidornot(topBidders, auction));
  }, [auction, topBidders]);
  useEffect(() => {
    // getAnalytics();
    socket.on("connect", () => {
      //console.log("Connected to Socket.IO server");
    });
    socket.on("analytics", (data) => {
      if (data.auctionId === auctionId) {
        setAnalytics(data.analytics);
      }
    });
    return () => {
      socket.off("analytics");
    };
  }, [analytics]);
  useEffect(() => {
    if (auction && !hasCalledanalytics.current) {
      views();
      const referreduser = isReferredUserExist(
        auction.ReferredUsers,
        auth.user.id,
      );
      if ((ref != null && refId != null) || referreduser) {
        const refc = refId || referreduser.refCode;
        referralVisit(refc);
      }
      getAnalytics();
      getadsBanner();
      getAllBiddersreg();
      hasCalledanalytics.current = true;
    }
  }, [auction]);
  useEffect(() => {
    fetchBalance();
    getAuctionDetails();
    gettopBidders();
    getallocatedQuant();

    socket.on("connect", () => {
      //console.log("Connected to Socket.IO server");
    });

    socket.on("bidUpdate", (data) => {
      if (data.auctionId === auctionId) {
        // console.log(auctionId);
        setTopBidders(data.topBidders);

        setAuction((prevAuction) => ({
          ...prevAuction,
          current_price: data.currentPrice,
          EndDate: data.auctionendDate,
          bidIncrement: data.bidIncrement
        }));
        setNextBid(calculateNextBid(data.topBidders, auctionQty));
        fetchBalance();
        getallocatedQuant();
      }
    });

    return () => {
      socket.off("bidUpdate");
    };
  }, []);
  useEffect(() => {
    socket.on("connect", () => {
      //console.log("Connected to Socket.IO server");
    });
    socket.on("offlinebidUpdate", (data) => {
      if (data.auctionId === auctionId) {
        setTopBidders(data.topBidders);
      }
    });
    return () => {
      socket.off("offlinebidUpdate");
    };
  }, []);

  useEffect(() => {
    if (!isInitialRender) {
      if (isMobile) {
        setIsBidOpen(false);
        setIsTopBiddersOpen(false);
        setIsChatOpen(false);
        setisAnalyticsopen(false);
        setIsOpen(false);
        setisbiddersOpen(false);
      } else {
        setIsBidOpen(true);
        setIsTopBiddersOpen(true);
        setIsChatOpen(true);
        setisAnalyticsopen(true);
        setIsOpen(true);
        setisbiddersOpen(true);
      }
    } else {
      setIsInitialRender(false);
    }
  }, [isMobile]);
  useEffect(() => {
    if (!isInitialRender) {
      if (isMobile) {
        if (
          isBidOpen ||
          isChatOpen ||
          isTopBiddersOpen ||
          isAnalyticsopen ||
          isBiddersopen ||
          isOpen
        ) {
          setIsAnyOpen(true);
        } else {
          setIsAnyOpen(false);
        }
      }
    } else {
      setIsInitialRender(false);
    }
  }, [
    isBidOpen,
    isChatOpen,
    isTopBiddersOpen,
    isAnalyticsopen,
    isOpen,
    isBiddersopen,
  ]);

  useEffect(() => {
    if (auction && !hasCalledRefferedUser.current && ref && refId) {
      const referreduser = isReferredUserExist(
        auction.ReferredUsers,
        auth.user.id,
      );
      if (!referreduser) {
        updatereferreduser();
      }
      hasCalledRefferedUser.current = true;
    }
  }, [auction]);

  useEffect(() => {
    setBidAmount(nextBid);
  }, [nextBid]);

  const handleWheel = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  const getAllBiddersreg = async () => {
    try {
      await axiosInstance(`/bidder/getAllBiddersofAuctionSecure/${auctionId}`).then(
        (res) => {
          setbidderreg(res.data);
          const currentBidderData = res.data.find(
            (bidder) => bidder.userID === auth.user.id,
          );
          setcurrBidderregData(currentBidderData || null);
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     try {
  //       const now = new Date();
  //       const endTime = new Date(auction?.EndDate);

  //       await getAuctionDetails(); // Await each function to ensure they complete before proceeding
  //       await gettopBidders();
  //       await getAnalytics();

  //       setMetrics(
  //         1000 +
  //           (1 * (analytics?.viewsCount.length || 0) + // Views
  //             20 * (auction?.message.length || 0) + // Live Chats
  //             10 * (analytics?.likes.length || 0) + // Likes
  //             15 * (analytics?.shareCount.length || 0) + // Shares
  //             1000 * (topBidders?.length || 0) + // Bids
  //             10 * (auction?.Users.length || 0)), // Registrations
  //       );

  //       if (now < endTime && metrics !== auction?.metrics) {
  //         await updateAuctionMetrics(auctionId, metrics); // Await the update function to ensure it's completed
  //       }
  //     } catch (error) {
  //       console.error("Error updating metrics:", error);
  //     }
  //   }, 60000); // Run every minute

  //   return () => clearInterval(interval); // Cleanup on component unmount
  // }, [metrics, auction, topBidders, analytics]);

  return (
    <>
      {!auction ? (
        <>Loading.....</>
      ) : (
        <div className="mx-2 mt-4 flex h-full flex-col gap-2 sm:mb-3 sm:mt-2 sm:flex-row sm:p-1 sm:pt-2">
          <Helmet>
        <title>{auction.Title}</title>
        <meta name="description" content={auction.description} />
        {/* <meta name="keywords" content="Trading, Bank Transfer, Add Funds, Payment Gateway, Refund" /> */}
        {/* <meta name="robots" content="index, follow" /> */}
        <meta property="og:title" content={auction.Title} />
        <meta property="og:description" content={auction.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location} />
        <meta property="og:image" content={auction?.isThumbnail ? auction?.thumbnail : auction.imageURL[0]} />
          </Helmet>
          {showpopup && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
              <div className="mx-auto w-full max-w-sm rounded-md bg-white p-4 shadow-lg md:max-w-md">
                <h2 className="mb-4 text-center text-lg font-semibold">
                  Confirm your BID
                </h2>
                <div className="mb-6">
                  <div className="mb-2 mr-2 flex justify-between">
                    <span>Bid Quantity [ A ] :</span>
                    <span className="flex gap-2">{bidQuantity} 
                    {auction?.unit ? auction?.unit : "unit(s)/lot(s)"}
                    </span>
                  </div>
                  <div className="mb-2 flex justify-between">
                    <span>Bid Price [ B ] :</span>
                    <span>₹ {Number(bidAmount).toLocaleString("en-IN")}</span>
                  </div>
                  <div className="mb-4 flex justify-between">
                    <span>Bid Value [ C = A x B ] :</span>
                    <span>₹ {Number(bidQuantity * bidAmount).toLocaleString("en-IN")}</span>
                  </div>
                  {currBidderregData.bidderEmd ? (<div className="text-green-600 font-semibold text-center">Dynamic EMD Enabled</div>) : ""}
                  {currBidderregData.bidderEmd ? (
                  <div className="flex justify-between font-semibold">
                    <span>EMD Percent : </span>
                    <span>{currBidderregData.emdPercent}%</span>
                  </div>) : ""}
                  <div className="flex justify-between font-semibold">
                    <span>Bid Transaction Amount : </span>
                    <span>₹ {Number(bidQuantity * bidAmount * currBidderregData.emdPercent / 100).toLocaleString("en-IN")}</span>
                  </div>
                  {currBidderregData.bidderEmd ? (
                  <div className="flex justify-between font-semibold">
                    <span>Unpaid Amount: </span>
                    <span>₹ {Number((bidQuantity * bidAmount) - (bidQuantity * bidAmount * currBidderregData.emdPercent / 100)).toLocaleString("en-IN")}</span>
                  </div>) : ""}
                  {currBidderregData.bidderEmd ? (
                  <div className="flex justify-between font-semibold">
                    <span>Unpaid Amount should be paid in : </span>
                    <span>{auction.emdBalance} days</span>
                  </div>) : ""}
                  {currBidderregData.bidderEmd ? (
                  <div className="flex mt-4 justify-between font-medium">
                    <span>Note: </span>
                    <span className="font-normal text-justify"> {auction.emdNotice}</span>
                  </div>) : ""}
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    onClick={!loading && (auction?.emdKyc ? (kycStatus ==="VERIFIED" ? placeBid : () => navigate(`/settings/kyc`)): placeBid)}
                    disabled={loading}
                    className={`cursor-pointer rounded-lg  font-semibold 
                      ${loading ? "border border-black bg-gray-300 text-black" : "bg-black text-white"} 
                      ${auction?.emdKyc ? (kycStatus ==="VERIFIED" ? "w-[50%]" : "w-[75%]"): "w-[50%]"}
                      px-4 py-2`}
                  >
                    {loading
                      ? "placing bid..."
                      : (auction?.emdKyc ? (kycStatus ==="VERIFIED" ? `BID with ₹ ${Number(bidQuantity * bidAmount * currBidderregData.emdPercent / 100).toLocaleString("en-IN")}` : "Get a KYC Verified profile to place bid"):`BID with ₹ ${Number(bidQuantity * bidAmount * currBidderregData.emdPercent / 100).toLocaleString("en-IN")}`) }
                  </button>
                  <button
                    onClick={() => setshowpopup(false)}
                    className={`text-md ${auction?.emdKyc ? (kycStatus ==="VERIFIED" ? "w-[50%]" : "w-[25%]"): "w-[50%]"} cursor-pointer rounded-md border border-black bg-gray-100 py-2`}
                  >
                    Cancel
                  </button>
                </div>
                {error && (
                  <div className="mt-4 flex w-full justify-center gap-5 text-sm font-semibold text-red-500">
                    <p>{errormsg}</p>
                    <span
                      className="cursor-pointer text-black"
                      onClick={() => {
                        seterror(false);
                        setshowpopup(false);
                        seterror("");
                      }}
                    >
                      X
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* medium size */}
          {isMobile && isAnyOpen && (
            <div className="mb-1 flex-col rounded pb-0 shadow-xl">
              {auction && (
                <div className="flex justify-between rounded-t-lg bg-gray-200 text-md font-bold text-black">
                  <Timer startDate={startDate} endDate={endDate} />
                  <div className="flex w-[20%] items-center justify-end gap-2 px-2">
                    {analytics?.likes.includes(auth.user.id) ? (
                      <IoHeart
                        className="h-8 w-8 cursor-pointer fill-red-500"
                        onClick={() => {
                          like();
                        }}
                      />
                    ) : (
                      <IoHeartOutline
                        className="h-8 w-8 cursor-pointer"
                        onClick={() => {
                          like();
                        }}
                      />
                    )}
                    <IoShareSocialOutline
                      className="h-8 w-8 cursor-pointer"
                      onClick={() => {
                        copyUrlToClipboard();
                        share();
                      }}
                    />
                  </div>
                </div>
              )}
              {auction?.Remarks &&
                (new Date(auction.EndTime) - new Date()) / (1000 * 60 * 60) >
                  0 &&
                (new Date(auction.EndTime) - new Date()) / (1000 * 60 * 60) <
                  1 && (
                  <div className="group flex overflow-hidden text-nowrap border-y border-black bg-gray-100 text-zinc-900">
                    <div className="flex max-w-none p-2">
                      <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction.Remarks}.{" "}
                      </p>
                      <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction.Remarks}.{" "}
                      </p>
                    </div>
                    <div className="aria-hidden:true flex max-w-none p-2">
                      <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction.Remarks}.{" "}
                      </p>
                      <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction.Remarks}.{" "}
                      </p>
                    </div>
                  </div>
                )}
              {auction?.visibility === "offline" && (
                <div className="group flex overflow-hidden text-nowrap border-y border-black bg-gray-100 text-zinc-900">
                  <div className="flex max-w-none p-2">
                    <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                      This is a record of offline auction, bids are managed by
                      host.{" "}
                    </p>
                    <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                      This is a record of offline auction, bids are managed by
                      host.{" "}
                    </p>
                  </div>
                  <div className="aria-hidden:true flex max-w-none p-2">
                    <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                      This is a record of offline auction, bids are managed by
                      host.{" "}
                    </p>
                    <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                      This is a record of offline auction, bids are managed by
                      host.{" "}
                    </p>
                  </div>
                </div>
              )}
              {auction?.ScrollingNote !== null && (
                    <div className="group flex overflow-hidden text-nowrap border-y border-black bg-gray-100 text-zinc-900">
                      <div className="flex max-w-none p-2">
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                          {auction?.ScrollingNote}
                        </p>
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction?.ScrollingNote}
                        </p>
                      </div>
                      <div className="aria-hidden:true flex max-w-none p-2">
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction?.ScrollingNote}
                        </p>
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction?.ScrollingNote}
                        </p>
                      </div>
                    </div>
              )}

              <div
                className=" flex items-start justify-start gap-2 "
                onClick={() => {
                  setIsBidOpen(false);
                  setIsTopBiddersOpen(false);
                  setIsChatOpen(false);
                  setisAnalyticsopen(false);
                  setIsOpen(false);
                  setisbiddersOpen(false);
                }}
              >
                <div className="flex aspect-1 h-fit w-[50%] items-center justify-center overflow-hidden">
                  <img
                    src={images}
                    alt={auction?.Title}
                    className="rounded-bl-sm object-cover"
                  />
                </div>

                <div className="flex h-full w-[65%] flex-col items-start justify-between gap-2 px-1 pt-0">
                  <div>
                    <h3 className="text-lg md:text-xl">
                      <span className="break-words font-medium">
                        {auction && clipText(auction.Title, 10)}{" "}
                      </span>{" "}
                      by{" "}
                      <span
                        className="cursor-pointer flex items-center break-words font-bold hover:underline"
                        onClick={() =>
                          navigate(`/profile/${auction?.HostName}`)
                        }
                      >
                        {auction && clipText(auction.HostName, 5)}
                        {userData?.user?.kycStatus === "VERIFIED" && (
                                <div  className=" ml-1 mt-1">
                                  <button>
                                    <MdVerifiedUser size={16} />
                                  </button>
                                </div>
                              )}
                      </span>
                    </h3>
                    <p className="break-words text-sm text-gray-500 sm:text-base">
                      {auction && clipText(auction.description, 20)}
                      {/* some Info */}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1 text-sm sm:text-base">
                    <p>
                      Auction ID:{" "}
                      <span className="font-bold">{auction?.AuctionId}</span>
                    </p>
                    {/* <p>
                      Auction Type:{" "}
                      <span className="font-bold">
                        {auction?.visibility === "public"
                          ? "Public Auction"
                          : auction?.visibility === "private"
                            ? "Private Auction"
                            : auction?.visibility === "restricted"
                              ? "Restricted Public Auction"
                              : "Offline Record"}
                      </span>
                    </p> */}
                    <p className="flex gap-2">
                      Available:{" "}
                      <span className="font-bold flex gap-2">{auction?.Quantity} 
                    <span className="">{auction?.unit ? auction?.unit : "unit(s)/lot(s)"}</span>
                      </span>
                    </p>
                    {/* <p>
                      Schedule:{" "}
                      <span className="font-medium">
                        {" "}
                        {new Date(auction?.StartDate).toDateString()},
                      </span>
                      <span className="font-medium">
                        {" "}
                        {new Date(auction?.StartDate).toLocaleTimeString()},
                      </span>
                      <span className="font-medium">
                        {" "}
                        {auction?.AuctionDuration === 168
                          ? "1 week"
                          : `${auction?.AuctionDuration} Hours`}
                      </span>
                    </p> */}
                    {auction?.Status === "Hold" && (
                      <div className="font-bold">Auction is Hold</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isAnyOpen && (
            <div
              className={`w-full sm:mb-0 sm:mr-0 sm:flex sm:w-[33%] sm:flex-col sm:items-center sm:justify-normal`}
            >
              <div className="max-w-full rounded border shadow-md">
                <div
                  className={`w-full ${isAnyOpen ? "" : "mx-auto"} w-9/12 ${
                    isAnyOpen ? "" : ""
                  } sm:mx-auto`}
                >
                  <div className="flex w-full flex-grow items-center rounded-t bg-gray-200">
                    {auction && (
                      <div className="w-[70%] justify-start rounded-t-md px-2 text-md font-bold text-black">
                        <Timer startDate={startDate} endDate={endDate} />
                      </div>
                    )}
                    <div className="flex w-[30%] justify-end gap-2 px-2">
                      {analytics?.likes.includes(auth.user.id) ? (
                        <IoHeart
                          className="h-8 w-8 cursor-pointer fill-red-500"
                          onClick={() => {
                            like();
                          }}
                        />
                      ) : (
                        <IoHeartOutline
                          className="h-8 w-8 cursor-pointer"
                          onClick={() => {
                            like();
                          }}
                        />
                      )}

                      <IoShareSocialOutline
                        className="h-8 w-8 cursor-pointer"
                        onClick={() => {
                          copyUrlToClipboard();
                          share();
                        }}
                      />
                    </div>
                  </div>
                  {auction?.Remarks &&
                    (new Date(auction.EndTime) - new Date()) /
                      (1000 * 60 * 60) >
                      0 &&
                    (new Date(auction.EndTime) - new Date()) /
                      (1000 * 60 * 60) <
                      1 && (
                      <div className="group flex overflow-hidden text-nowrap border-y border-black bg-gray-100 text-zinc-900">
                        <div className="flex max-w-none p-2">
                          <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                            {auction.Remarks}.{" "}
                          </p>
                          <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                            {auction.Remarks}.{" "}
                          </p>
                        </div>
                        <div className="aria-hidden:true flex max-w-none p-2">
                          <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                            {auction.Remarks}.{" "}
                          </p>
                          <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                            {auction.Remarks}.{" "}
                          </p>
                        </div>
                      </div>
                    )}
                  {auction?.visibility === "offline" && (
                    <div className="group flex overflow-hidden text-nowrap border-y border-black bg-gray-100 text-zinc-900">
                      <div className="flex max-w-none p-2">
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                          This is a record of offline auction, bids are managed
                          by host.{" "}
                        </p>
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                          This is a record of offline auction, bids are managed
                          by host.{" "}
                        </p>
                      </div>
                      <div className="aria-hidden:true flex max-w-none p-2">
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                          This is a record of offline auction, bids are managed
                          by host.{" "}
                        </p>
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                          This is a record of offline auction, bids are managed
                          by host.{" "}
                        </p>
                      </div>
                    </div>
                  )}
                  {auction?.ScrollingNote !== null && (
                    <div className="group flex overflow-hidden text-nowrap border-y border-black bg-gray-100 text-zinc-900">
                      <div className="flex max-w-none p-2">
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                          {auction?.ScrollingNote}
                        </p>
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction?.ScrollingNote}
                        </p>
                      </div>
                      <div className="aria-hidden:true flex max-w-none p-2">
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction?.ScrollingNote}
                        </p>
                        <p className="no-scroll animate-scroll flex-1 pl-2 group-hover:paused">
                        {auction?.ScrollingNote}
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Image */}
                  <div className="aspect-1 relative">
                    {/* <img
                  src={auction?.imageURL}
                  alt="Oil Painting"
                  className="h-[100%] w-full object-fill"
                 */}
                    {images && <Carousel images={images} />}
                    <div className="">
                    <div className="flex w-full justify-start border-b gap-1 px-2 py-2">
                    {analytics?.viewsCount?.length > 0 && (
                      <div className="flex w-[20%] flex-row items-center gap-1 justify-center">
                        <IoEyeOutline className="h-6 w-6" />
                        <p className="font-semibold">
                          {formatCount(
                            analytics?.viewsCount?.length +
                              analytics?.unauthorisedViews?.length,
                          )}
                        </p>
                      </div>
                    )}
                    {topBidders.length > 0 && (
                      <div className="flex w-[20%] flex-row items-center gap-1 cursor-pointer justify-center"
                          onClick={toggleTopBidders}
                      >
                        <RiAuctionLine className="h-6 w-6" />
                        <p className="font-semibold">
                          {formatCount(topBidders.length)}
                        </p>
                      </div>
                    )}
                    { (
                      <div className="flex w-[20%] flex-row items-center gap-1 justify-center">
                        {analytics?.likes.includes(auth.user.id) ? (
                        <IoHeart
                          className="h-6 w-6 cursor-pointer fill-red-500"
                          onClick={() => {
                            like();
                          }}
                        />
                      ) : (
                        <IoHeartOutline
                          className="h-6 w-6 cursor-pointer"
                          onClick={() => {
                            like();
                          }}
                        />
                      )}
                        <p className="font-semibold">
                          {analytics?.likes.length >0 && formatCount(analytics?.likes.length)}
                        </p>
                      </div>
                    )}
                    {auction?.message.length > 0 && (
                      <div className="flex w-[20%] flex-row items-center gap-1 cursor-pointer justify-center" 
                          onClick={toggleChat}
                        >
                        <IoChatbubbleOutline className="h-6 w-6" />
                        <p className="font-semibold">
                          {formatCount(auction?.message.length)}
                        </p>
                      </div>
                    )}
                    { (
                      <div className="flex w-[20%] flex-row items-center gap-1 justify-center">
                        <IoShareSocialOutline className="h-6 w-6" onClick={() => {copyUrlToClipboard();share();}}/>
                        <p className="font-semibold">
                          {analytics?.shareCount.length>0 && formatCount(analytics?.shareCount.length)}
                        </p>
                      </div>
                    )}
                    </div>
                    </div>
                  </div>
                </div>

                {/* Description */}
                <div
                  className={`mx-auto mb-0 flex h-fit w-full flex-col justify-between bg-white px-3 py-2 sm:mx-auto`}
                >
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="break-words text-lg font-medium">
                        {auction?.Title}{" "}
                      </span>{" "}
                      by{" "}
                      <div
                        className="cursor-pointer w-fit flex items-center break-words text-lg font-bold"
                        onClick={() =>
                          navigate(`/profile/${auction?.HostName}`)
                        }
                      >
                        {" "}
                        {auction?.HostName}
                        {userData?.user?.kycStatus === "VERIFIED" && (
                                <div  className=" ml-1 mt-1">
                                  <button>
                                    <MdVerifiedUser size={16} />
                                  </button>
                                </div>
                              )}
                      </div>{" "}
                      {auction?.CollabStatus==="Successful" && (
                        <div>
                      <span className="text-sm">in collaboration with{" "}</span>
                      <span
                        className="cursor-pointer flex items-center break-words text-lg font-bold"
                        onClick={() =>
                          navigate(`/profile/${collabs[0]?.userName}`)
                        }
                      >
                        {" "}
                        {collabs[0]?.userName}
                        {collabs[0]?.kycStatus === "VERIFIED" && (
                                <div  className=" ml-1">
                                  <button>
                                    <MdVerifiedUser size={16} />
                                  </button>
                                </div>
                              )}
                      </span>{" "}
                      </div>
                      )}
                    </div>

                    <p className="=sm:mb-0 break-words" style={{ whiteSpace: "pre-line" }}>
                      {auction?.description}
                    </p>
                    <p className="mt-3 font-semibold sm:mb-0">
                      <span className="font-normal">Category: </span>{" "}
                      {auction?.Category}
                    </p>
                    <p className="font-bold flex gap-2">
                      {" "}
                      <span className="font-normal flex gap-2">Available Quantity: </span>
                      {auction?.Quantity} 
                    <span>{auction?.unit ? auction?.unit : "unit(s)/lot(s)"}</span>
                    </p>
                    <p className="font-semibold sm:mb-0">
                      <span className="font-normal">Auction ID: </span>{" "}
                      {auction?.AuctionId}
                    </p>
                    <p className="font-semibold sm:mb-0">
                      <span className="font-normal">Auction Visibility: </span>{" "}
                      {auction?.visibility === "public"
                        ? "Public Auction"
                        : auction?.visibility === "private"
                          ? "Private Auction"
                          : auction?.visibility === "restricted"
                            ? "Restricted Public Auction"
                            : "Offline Record"}
                    </p>
                    <p>
                    Auction Schedule:{" "}
                      <span className="font-medium">
                        {" "}
                        {new Date(auction?.StartDate).toDateString()},
                      </span>
                      <span className="font-medium">
                        {" "}
                        {new Date(auction?.StartDate).toLocaleTimeString()}
                      </span>
                      
                    </p>
                    <p>
                    Auction Duration:{" "}
                        <span className="font-medium">
                          {" "}
                          {auction?.AuctionDuration === 168
                            ? "1 week"
                            : auction?.AuctionDuration === 720 ? "30 Days" : `${auction?.AuctionDuration} Hours`}
                        </span>
                    </p>
                    {auction?.eventLocation && 
                    <p>
                      <span className="underline flex cursor-pointer items-center font-normal"
                    onClick={() => window.open(auction?.eventLocation)}
                    ><FaMapLocationDot className="mr-2"/>Location</span>
                    </p>}
                    {auction?.Status === "Hold" && (
                      <div className="font-bold">Auction is Hold</div>
                    )}
                  </div>
                </div>
              </div>

              {/* More Information */}
              {(auction?.Provenance || auction?.Condition ||auction?.moreDetails.length>0) && (
              <div className="mx-auto mt-2 hidden w-full rounded border shadow-md sm:mt-1 sm:block">
                <button
                  className="flex w-full justify-between rounded px-4 py-2 shadow-md"
                  onClick={toggleDiv}
                >
                  <span className="font-semibold text-gray-500">
                    More Information{" "}
                  </span>
                  <span>{isOpen ? "-" : "+"}</span>
                </button>
                {isOpen && (
                  <div className="sm:mb-1">
                    <div className="mb-1 flex flex-col gap-2 p-4">
                      {auction.Videos ||
                      auction.Provenance ||
                      auction.Condition ? (
                        <>
                          {auction.Videos ? (
                            <a
                              target="_blank"
                              href={auction?.Videos}
                              className="cursor-pointer"
                            >
                              Videos
                            </a>
                          ) : null}

                          {auction.Provenance ? (
                            <a
                              target="_blank"
                              href={auction?.Provenance}
                              className="cursor-pointer flex gap-1 items-center underline decoration-dashed"
                            >
                            <FaRegFile/>  {auction?.provName}
                            </a>
                          ) : null}

                          {auction.Condition ? (
                            <a
                              target="_blank"
                              href={auction?.Condition}
                              className="cursor-pointer flex gap-1 items-center underline decoration-dashed"
                            >
                            <FaRegFile/>  {auction?.condName}
                            </a>
                          ) : null}
                          {auction?.moreDetails.length>0  &&
                          <div className="flex flex-col gap-2">
                            {auction?.moreDetails?.map(item => 
                            <div className="flex flex-col">
                              <div >{item.question}</div>
                              <div className="font-medium">{item.answer}</div>
                            </div>
                            )}
                          </div>}
                        </>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                )}
              </div>)}
              {/* Auction Analytics  */}
              {/* <div className="mx-auto hidden w-full rounded border shadow-md sm:mb-6 sm:mt-2 sm:block">
                <button
                  className="flex w-full justify-between rounded px-4 py-2 shadow-md"
                  onClick={toggleAnalytics}
                >
                  <span className="font-semibold text-gray-500">Insights </span>
                  <span>{isAnalyticsopen ? "-" : "+"}</span>
                </button>
                {isAnalyticsopen && (
                  <div className="flex w-full justify-evenly gap-3 px-2 py-2">
                    {analytics?.viewsCount?.length > 0 && (
                      <div className="flex w-[20%] flex-col items-center justify-center">
                        <p className="font-semibold">
                          {formatCount(
                            analytics?.viewsCount?.length +
                              analytics?.unauthorisedViews?.length,
                          )}
                        </p>
                        <IoEyeOutline className="h-6 w-6" />
                      </div>
                    )}
                    {topBidders.length > 0 && (
                      <div className="flex w-[20%] flex-col items-center justify-center">
                        <p className="font-semibold">
                          {formatCount(topBidders.length)}
                        </p>
                        <RiAuctionLine className="h-6 w-6" />
                      </div>
                    )}
                    {auction?.message.length > 0 && (
                      <div className="flex w-[20%] flex-col items-center justify-center">
                        <p className="font-semibold">
                          {formatCount(auction?.message.length)}
                        </p>
                        <IoChatbubbleOutline className="h-6 w-6" />
                      </div>
                    )}
                    {analytics?.likes.length > 0 && (
                      <div className="flex w-[20%] flex-col items-center justify-center">
                        <p className="font-semibold">
                          {formatCount(analytics?.likes.length)}
                        </p>
                        <IoHeartOutline className="h-6 w-6" />
                      </div>
                    )}
                    {analytics?.shareCount?.length > 0 && (
                      <div className="flex w-[20%] flex-col items-center justify-center">
                        <p className="font-semibold">
                          {formatCount(analytics?.shareCount.length)}
                        </p>
                        <IoShareSocialOutline className="h-6 w-6" />
                      </div>
                    )}
                  </div>
                )}
              </div> */}
            </div>
          )}

          <div className="lg:5/12 sm:flex sm:w-[33%] sm:flex-col sm:items-center">
            {/* Top Bidders */}  
            <div
              className={`${
                isAnyOpen ? "" : ""
              } mx-auto rounded border border-gray-200 bg-white p-2 px-4 shadow-md sm:w-full`}
            >
              <div
                className="mb-1 flex w-full cursor-pointer items-center justify-between"
                onClick={toggleTopBidders}
              >
                <div className="font-semibold text-gray-500">Top Bidders</div>
                <div className="cursor-pointer font-bold text-gray-500">
                  {isTopBiddersOpen ? <span>-</span> : <span>+</span>}
                </div>
              </div>
              {isTopBiddersOpen && (
                <div className="border-t border-black py-2">
                  {/* Ad Banner */}
                  {auction?.Ads === "Custom" ? (
                    <div
                      className="aspect-h-1 aspect-w-4 mb-2 flex cursor-pointer items-center justify-center"
                      onClick={
                        auction?.customAdUrl &&
                        (() => window.open(auction?.customAdUrl))
                      }
                    >
                      <img
                        src={auction?.auctionAdBanner?.url}
                        alt="promotion"
                        className="h-full rounded-md border border-black object-fill shadow-md"
                      />
                      <span className="p-1 pl-2 text-xs font-medium"></span>
                    </div>
                  ) : auction?.Ads === "Yes" ? (
                    auction?.adminAd && auction?.adminAd.length > 0 ? (
                      <AdCarousel
                        adminAd={auction?.adminAd}
                        defaultImage={adsbanner[1]?.image}
                        defaultUrl={adsbanner[1]?.url}
                        auctionId={auction.AuctionId}
                        userId={auth.user.id || null}
                      />
                    ) : (
                      <div
                        className="aspect-h-1 aspect-w-4 mb-2 mt-1 flex cursor-pointer items-center justify-center"
                        onClick={
                          adsbanner[1]?.url &&
                          (() => window.open(adsbanner[1]?.url))
                        }
                      >
                        {/* {console.log("Non admin")} */}
                        <img
                          src={adsbanner[1]?.image}
                          alt="ad"
                          className="h-full rounded-md border border-black object-fill shadow-md"
                        />
                        <span className="p-1 pl-2 text-xs font-medium">Ad</span>
                      </div>
                    )
                  ) : (
                    ""
                  )}

                  {/* Bidders List */}
                  <div className="border-b border-black py-1">
                    {topBidders.length > 0 ? (
                      <div className="max-h-[240px] overflow-y-auto">
                        {topBidders.map((b) => (
                          <BiddersCard
                            key={b._id}
                            b={b}
                            aid={auctionId}
                            uid={auth.user.id}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="mb-2">
                        Bid to get " {auction?.Title} "
                      </div>
                    )}
                    <div></div>
                  </div>

                  {/* Winning and bid more */}
                  {isRegistered ? (
                    new Date() > new Date(auction?.StartDate) &&
                    (auction?.visibility === "offline" ? (
                      ""
                    ) : (
                      <div className="flex flex-row items-center justify-between border-b border-black p-2 font-bold">
                        <div>
                          {allocatedQuant > 0 ? (
                            <div>
                              
                              <p className="flex gap-2 items-baseline">
                                {" "}
                                <span className="text-xs flex items-baseline font-normal">
                                  Allotted: {"   "}
                                </span>
                                <span className=" flex items-baseline gap-2 text-2xl font-black">
                                  {" "}
                                  {allocatedQuant}
                                  <span className="text-xs">{auction?.unit ? auction?.unit : "unit(s)/lot(s)"}</span>
                              </span>
                              </p>
                              <p className="text-sm font-semibold">
                                Winning Bid
                              </p>
                            </div>
                          ) : (
                            <div>
                              <p className="text-sm font-medium">Bid now</p>
                            </div>
                          )}
                          {/* / <br /> */}
                        </div>
                        <div>
                          {/* <button
                      className="rounded-lg hover:text-white text-black bg-white hover:bg-gray-200  p-1 text-sm font-bold shadow-lg"
                      
                    >
                      
                    </button> */}
                          <Button
                            className="p-1 px-2 font-semibold shadow-lg"
                            variant="outline"
                            onClick={handleBiddingSummaryClick}
                          >
                            Bidding Summary
                          </Button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>

            {/* Bid Section */}
            <div
              className={`${
                isAnyOpen ? "" : ""
              } mx-auto mt-2 rounded border border-gray-200 bg-white p-2 px-4 shadow-md sm:mt-2 sm:w-full`}
            >
              <div
                className="flex w-full cursor-pointer items-center justify-between"
                onClick={toggleBid}
              >
                <div className="font-semibold text-gray-500">Bid</div>
                <div className="cursor-pointer font-bold text-gray-500">
                  {isBidOpen ? <span>-</span> : <span>+</span>}
                </div>
              </div>

              {isBidOpen && (
                <div className="mt-2 border-t border-black pt-2">
                  {auction?.current_price > 0 && (
                    <div className="text-md font-small mb-4 mt-4 flex gap-2 items-center">
                      Highest Bid :{" "}
                      <span className="text-md ml-2 rounded border-2 border-gray-200 p-1 px-4 font-semibold shadow-md">
                        ₹ {Number(auction?.current_price).toLocaleString("en-IN") ? Number(auction?.current_price).toLocaleString("en-IN") : 0}
                        
                      </span>{" "}
                      <span className="font-small text-sm text-gray-500">
                        {auction?.unit ? `per ${auction?.unit.split('(')[0]}` : "per unit"}
                      </span>{" "}
                    </div>
                  )}
                  <div className="text-md font-small mb-2 mt-2 flex gap-2 items-center">
                    Reserve Price :{" "}
                    <span className="text-md font-bold">
                      ₹ {Number(auction?.reserved_price).toLocaleString("en-IN")}
                    </span>{" "}
                    <span className="font-small text-sm text-gray-500">
                    {auction?.unit ? `per ${auction?.unit.split('(')[0]}` : "per unit"}
                    </span>{" "}
                  </div>
                  {auction?.isBankAuction &&
                  <div className="text-md font-small mb-4 mt-4">
                    EMD :{" "}
                    <span className="text-md font-bold">
                      ₹ {Number(auction?.bankAuctionEMD).toLocaleString("en-IN")}
                    </span>{" "}
                  </div>}
                  {auction?.isBankAuction &&
                  <div className="text-md font-small mb-4 mt-4">
                    Bid Increment :{" "}
                    <span className="text-md font-bold">
                      ₹ {Number(auction?.bankAuctionIncrement).toLocaleString("en-IN")}
                    </span>{" "}
                  </div>}
                  {!auction?.isBankAuction && shownextBid&&
                  <div className="text-md font-small mb-4 mt-4">
                    Dynamic Bid Increment :{" "}
                    <span className="text-md font-bold">
                      ₹ {Number(auction?.bidIncrement).toLocaleString("en-IN")}
                    </span>{" "}
                  </div>}
                  <div className="text-md font-small mb-4 mt-4 flex gap-2 items-baseline">
                    Available Quantity :{" "}
                    <span className="text-md font-bold flex gap-2">
                      {auction?.Quantity} 
                    </span>{" "}
                    <span className="font-small text-sm text-gray-500">
                    {auction?.unit ? auction?.unit : "unit(s)/lot(s)"}
                    </span>{" "}
                  </div>
                  {auction?.Status === "Hold" && (
                    <div className="font-bold">
                      Auction is Hold & restricted to place new bids. If any
                      bids are already placed, we will review and confirm your
                      bids.
                    </div>
                  )}
                  {auction?.visibility === "offline" && (
                    <div className="font-bold">
                      This is an Offline Auction Record, to place a bid or any
                      other quiries contact host.
                    </div>
                  )}
                  {new Date() < new Date(auction?.EndDate) &&
                    auction?.Status !== "Hold" &&
                    (auction?.visibility === "offline" ? (
                      ""
                    ) : (
                      <div className="items-center justify-center">
                        {shownextBid && (
                          <NextBid
                            time={auction?.EndTime}
                            topBidders={topBidders}
                            price={auction?.current_price}
                            rprice={auction?.reserved_price}
                            qty={auction?.Quantity}
                            bidIncrement={auction?.bidIncrement}
                          />
                        )}
                      </div>
                    ))}

                  {new Date() < new Date(auction?.EndDate) &&
                    auction?.Quantity > 1 &&
                    auction?.Status !== "Hold" &&
                    isRegistered &&
                    (auction?.visibility === "offline" ? (
                      ""
                    ) : (
                      <div className="text-md mt-3 flex w-full items-center justify-start gap-4 rounded-lg py-1">
                        <h3 className="text-md font-medium">
                          Select quantity:
                        </h3>
                        <div className="text-md flex gap-6 rounded border-2 p-1 font-semibold">
                          <div
                            className="flex cursor-pointer items-center justify-center border-r-2 p-1 px-3"
                            onClick={() => {
                              setBidquantity((prev) => {
                                if (prev == 1) {
                                  return prev;
                                } else {
                                  return prev - 1;
                                }
                              });
                            }}
                          >
                            <FaMinus />
                          </div>
                          <div className="px-2 text-lg font-semibold">
                            {bidQuantity}
                          </div>
                          <div
                            className="flex cursor-pointer items-center justify-center border-l-2 p-1 px-3"
                            onClick={() => {
                              setBidquantity((prev) => {
                                if (prev == auctionQty) {
                                  return prev;
                                } else {
                                  return prev + 1;
                                }
                              });
                            }}
                          >
                            <FaPlus />
                          </div>
                        </div>
                      </div>
                    ))}

                  {isRegistered  ? (
                    <div>
                      {new Date() < new Date(auction?.EndDate) &&
                        auction?.Status !== "Hold" &&
                        auction?.visibility !== "offline" && (
                          <div className="mt-2 flex flex-col items-center mb-2">
                            <div className="mt-2 flex flex-row items-center justify-center gap-2">
                              <input
                                type="number"
                                // onwheel="return false"
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false },
                                  )
                                }
                                onWheel={handleWheel}
                                onKeyDown={handleKeyDown}
                                className="mb-2 w-5/12 rounded border px-4 py-2"
                                value={bidAmount}
                                placeholder="enter amount"
                                onChange={(e) => {
                                  seterror(false);
                                  seterrormsg("");
                                  setBidAmount(e.target.value);
                                }}
                                min={auction?.reserved_price}
                              />
                              {loading ? (
                                <button
                                  className="w-7/12 rounded-sm bg-black px-6 py-2 font-medium text-white shadow-md transition-transform duration-300 ease-in-out hover:-translate-y-1 hover:transform hover:shadow-xl"
                                  disabled={true}
                                >
                                  placing bid...
                                </button>
                              ) : (
                                <button
                                  className="w-7/12 rounded-sm bg-black px-6 py-2 font-medium text-white shadow-md transition-transform duration-300 ease-in-out hover:-translate-y-1 hover:transform hover:shadow-xl"
                                  onClick={() => setshowpopup(true)}
                                  disabled={false}
                                >
                                  {bidAmount>0 ? `BID for ₹ ${Number(bidQuantity * bidAmount).toLocaleString("en-IN")}`: "Bid"}
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  ) : (
                    auction?.visibility !== "offline" && !isRegistered &&(
                      <div className="mt-4 flex flex-row items-center justify-center mb-2">
                        {new Date() < new Date(auction?.EndDate) &&
                          auction?.Status !== "Hold" && (
                            <button
                              onClick={() =>
                                navigate(`/auction-register/${auction._id}`)
                              }
                              className="rounded-sm border-2 border-black bg-black px-6 py-2 font-bold text-white transition-transform duration-300 ease-in-out hover:-translate-y-1 hover:transform hover:shadow-xl"
                            >
                              Register to bid
                            </button>
                          )}
                      </div>
                    )
                  )}
                  {auction?.Status !== "Hold" && (
                    <div className="pt-4 border-t flex flex-col items-center">
                      <p className="font-normal">
                        Account Balance:{" "}
                        <span className="font-extrabold">₹ {Number(balance).toLocaleString("en-IN")}</span>{" "}
                      </p>
                      <Button
                        className="mb-2 mt-3 w-fit rounded-lg px-4 py-3 font-semibold shadow-lg"
                        variant="outline"
                        onClick={() => navigate("/trading")}
                      >
                        Add Funds
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* More Information */}
            {(auction?.Provenance || auction?.Condition ||auction?.moreDetails.length>0) && (
            <div
              className={`${
                isAnyOpen ? "" : ""
              } mx-auto mt-2 rounded border shadow-sm sm:hidden`}
            >
              <button
                className="flex w-full justify-between rounded px-4 py-2 shadow-md"
                onClick={toggleDiv}
              >
                <span className="font-semibold text-gray-500">
                  More Information{" "}
                </span>
                <span>{isOpen ? "-" : "+"}</span>
              </button>
              {isOpen && (
                  <div className="sm:mb-1">
                    <div className="mb-1 flex flex-col gap-2 p-4">
                      {auction.Videos ||
                      auction.Provenance ||
                      auction.Condition ? (
                        <>
                          {auction.Videos ? (
                            <a
                              target="_blank"
                              href={auction?.Videos}
                              className="cursor-pointer"
                            >
                              Videos
                            </a>
                          ) : null}

                          {auction.Provenance ? (
                            <a
                              target="_blank"
                              href={auction?.Provenance}
                              className="cursor-pointer flex gap-1 items-center underline decoration-dashed"
                            >
                            <FaRegFile/>  {auction?.provName}
                            </a>
                          ) : null}

                          {auction.Condition ? (
                            <a
                              target="_blank"
                              href={auction?.Condition}
                              className="cursor-pointer flex gap-1 items-center underline decoration-dashed"
                            >
                            <FaRegFile/>  {auction?.condName}
                            </a>
                          ) : null}
                          {auction?.moreDetails.length>0  &&
                          <div className="flex flex-col gap-2">
                            {auction?.moreDetails?.map(item => 
                            <div className="flex flex-col">
                              <div >{item.question}</div>
                              <div className="font-medium">{item.answer}</div>
                            </div>
                            )}
                          </div>}
                        </>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                )}
            </div>)}
            {/* Auction Analytics  */}
            {/* <div className="mx-auto mt-2 w-full rounded border shadow-sm sm:hidden">
              <button
                className="flex w-full justify-between rounded px-4 py-2 shadow-md"
                onClick={toggleAnalytics}
              >
                <span className="font-semibold text-gray-500">Insights</span>
                <span>{isAnalyticsopen ? "-" : "+"}</span>
              </button>
              {isAnalyticsopen && (
                <div className="flex w-full justify-center gap-3 px-2 py-2">
                  {analytics?.viewsCount.length > 0 && (
                    <div className="flex w-[20%] flex-col items-center justify-center">
                      <p className="font-semibold">
                        {formatCount(
                          analytics?.viewsCount.length +
                            analytics?.unauthorisedViews.length,
                        )}
                      </p>
                      <IoEyeOutline className="h-6 w-6" />
                    </div>
                  )}
                  {topBidders.length > 0 && (
                    <div className="flex w-[20%] flex-col items-center justify-center">
                      <p className="font-semibold">
                        {formatCount(topBidders.length)}
                      </p>
                      <RiAuctionLine className="h-6 w-6" />
                    </div>
                  )}
                  {auction?.message.length > 0 && (
                    <div className="flex w-[20%] flex-col items-center justify-center">
                      <p className="font-semibold">
                        {formatCount(auction?.message.length)}
                      </p>
                      <IoChatbubbleOutline className="h-6 w-6" />
                    </div>
                  )}
                  {analytics?.likes.length > 0 && (
                    <div className="flex w-[20%] flex-col items-center justify-center">
                      <p className="font-semibold">
                        {formatCount(analytics?.likes.length)}
                      </p>
                      <IoHeartOutline className="h-6 w-6" />
                    </div>
                  )}
                  {analytics?.shareCount?.length > 0 && (
                    <div className="flex w-[20%] flex-col items-center justify-center">
                      <p className="font-semibold">
                        {formatCount(analytics?.shareCount?.length)}
                      </p>
                      <IoShareSocialOutline className="h-6 w-6" />
                    </div>
                  )}
                </div>
              )}
            </div> */}
          </div>
          <div className={`mx-auto w-full rounded sm:w-[34%]`}>
            {/* Chat Section */}
            <div
              className={`${
                isChatOpen ? "h-fit" : "h-fit"
              } mx-auto mb-2 w-full rounded border border-gray-200 bg-white p-2 px-4 shadow-md md:mb-5 md:shadow-md lg:shadow-md`}
            >
              <div
                className="flex w-full cursor-pointer items-center justify-between"
                onClick={toggleChat}
              >
                <div className="font-semibold text-gray-500">Live Chat</div>
                <div className="cursor-pointer font-bold text-gray-500">
                  {isChatOpen ? <span>-</span> : <span>+</span>}
                </div>
              </div>

              {isChatOpen && (
                <div className="mt-1 flex w-full flex-col justify-end border-t border-black">
                  <div className="border  mt-2 flex h-full w-full items-center justify-between rounded border-gray-200 shadow-md">
                    <div className="flex grid h-full w-full items-center  overflow-hidden pt-1">
                      <div
                          className="text-medium h-6 flex cursor-pointer items-center break-all px-3 font-bold"
                          onClick={() =>
                            navigate(`/profile/${userData?.user.userName}`)
                          }
                        >
                          @{userData?.user.userName}
                          {userData?.user?.kycStatus === "VERIFIED" && (
                                <div  className=" ml-1 mt-1">
                                  <button>
                                    <MdVerifiedUser size={16} />
                                  </button>
                                </div>
                              )}
                        </div>
                      <div className="flex grid h-full w-full grid-cols-2">
                      {/* left */}
                      <div className="h-full w-full flex-col items-center justify-evenly overflow-hidden pl-1 pt-1">
                        <div className="items-top h-fit w-fit justify-normal pt-0.5">
                          <div
                            className="items-top grid cursor-pointer grid-flow-col grid-rows-3 break-words pl-2"
                            onClick={() =>
                              navigate(`/profile/${userData?.user.userName}`)
                            }
                          >
                            <div className="items-top row-span-3 mr-0 mt-0 mt-1">
                              <div>
                              {userData?.user.profileImage ? (
                                <img
                                  className="mr-3 h-9 w-9 rounded-full object-cover shadow-2xl"
                                  src={userData?.user.profileImage}
                                  alt={userData?.user.userName}
                                />
                              ) : (
                                <img
                                  className="mr-3 h-9 w-9 rounded-full object-cover opacity-50 shadow-2xl"
                                  src={profile}
                                  alt={userData?.user.userName}
                                />
                              )}
                              </div>
                              {userData?.kycStatus==="VERIFIED" &&
                                <div>   <MdVerifiedUser size={16} />
                                    </div>}
                            </div>
                            <div className="breaks-words col-span-2 flex text-left text-[0.8rem] font-semibold text-slate-800">
                              <span>{userData?.user.name}</span>
                            </div>
                            <div className="-pt-1 col-span-2 row-span-2 overflow-hidden break-words text-[0.6rem] text-gray-800">
                              <p>{`${userData?.user.bio ? `${clipText(userData?.user.bio, 40)}` : ``}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* right */}
                      <div className=" flex h-full w-full flex-col items-center pb-1 pt-1">
                        <div className="flex justify-between gap-5">
                          {followers>0 && (
                          <div className="flex flex-col items-center justify-between font-normal">
                            <span className="text-[0.9rem] font-[400] font-medium text-gray-500">
                              {followers}
                            </span>
                            <div className="text-center text-[0.8rem] font-[400] text-gray-500">
                              followers
                            </div>
                          </div>)}
                          {posts>0 && (
                          <div className="flex flex-col items-center justify-normal font-normal">
                            <span className="text-[0.9rem] font-[400] font-medium text-gray-500">
                              {posts}
                            </span>
                            <div className="text-center text-[0.8rem] font-[400] text-gray-500">
                              auctions
                            </div>
                          </div>)}
                        </div>
                      </div>
                      
                      </div>
                      <div className="flex  justify-evenly m-2 gap-2">
                              {!isFollowing && (
                              <Button
                              variant={isFollowing ? "secondary" : ""}
                              className={isFollowing ? "h-7 w-full" : "h-7 w-full bg-slate-700"}
                              onClick={isFollowing ? handleUnfollow : handleFollow}
                              >
                                {isFollowing ? "Following" : "Follow"}
                              </Button>)}
                            
                            
                              <button
                                className="h-7 w-full rounded-sm bg-slate-700 text-center text-[0.8rem] font-medium text-white"
                                onClick={() => {
                                  if (userData?.user._id === auth.user.id) {
                                    toast.error("Can't message yourself");
                                  } else {
                                    navigate(
                                      `/chat/private/${userData?.user.userName}`,
                                    );
                                  }
                                }}
                              >
                                Nob Chat
                              </button>
                            
                        </div>
                    </div>
                  </div>
                  <div className="overflow-y-auto">
                    {/* Added height and overflow properties */}
                    {auction !== null && <LiveChatBox auction={auction} />}
                  </div>
                </div>
              )}
            </div>
            {Bidderreg.length > 0 && (
            <div
              className={`${
                isAnyOpen ? "" : ""
              } mx-auto rounded border border-gray-200 bg-white p-2 px-4 shadow-md sm:w-full`}
            >
              <div
                className="mb-1 flex w-full cursor-pointer items-center justify-between"
                onClick={toggleBidders}
              >
                <div className="font-semibold text-gray-500">
                  Registered User(s)
                </div>
                <div className="cursor-pointer font-bold text-gray-500">
                  {isBiddersopen ? <span>-</span> : <span>+</span>}
                </div>
              </div>

              {isBiddersopen && (
                <div className="border-t border-black py-2">
                  <div className="border-b border-black py-1">
                    {Bidderreg.length > 0 ? (
                      <div className="max-h-[240px] overflow-y-auto">
                        {Bidderreg.map((b) => (
                          ((b?.isBankAuction && b?.regStatusBankAuction==="approved")|| !b?.isBankAuction) &&
                          <div className="flex items-center  justify-start px-4 p-0.5">
                            {(b.regType === "self" && b.BidderId !== "username") && (
                              <div className="font-bold">
                                {b.BidderRegistrationId}
                              </div>
                            )}
                            {b.BidderId === "username" &&
                              (b.regType !== "host" ? (
                                <div
                                  className="cursor-pointer  font-bold"
                                  onClick={() =>
                                    navigate(`/profile/${b.userName}`)
                                  }
                                >
                                  
                                  {b.BidderId === "username"
                                    ? `${b.userName.length > 30 ? `${b.userName.substring(0, 30)}...` : b.userName}`
                                    : "Anonymous"}
                                </div>
                              ) : (
                                <div className="font-bold">
                                  {b.BidderId === "username"
                                    ? `${b.userName.length > 35 ? `${b.userName.substring(0, 35)}...` : b.userName}`
                                    : "Anonymous"}
                                </div>
                              ))}
                            {b.BidderId !== "username" && (
                              <div className="ml-auto">
                                {b.BidderId === "username"
                                  ? b.userName
                                  : "Anonymous"}
                              </div>
                            )}
                          </div>)
                        )}
                      </div>
                    ) : (
                      <div className="mb-2">Register to bid</div>
                    )}
                  </div>
                </div>
              )}
            </div>)}
          </div>
        </div>
      )}
    </>
  );
};

export default BiddingHome;
