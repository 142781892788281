import React, { lazy } from "react";

// Lazy load the private chat component
const PrivateChat = lazy(() => import("@/pages/PrivateChat/PrivateChatMain"));

const chatRoutes = [
  { path: "/chat/private", element: <PrivateChat /> },
  { path: "/chat/private/:id", element: <PrivateChat /> },
];

export default chatRoutes;
