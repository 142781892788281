import React, { lazy } from "react";

// Lazy load the auction center components
const AuctionCenter = lazy(() => import("@/pages/AuctionCenter/AuctionCenter"));
const AuctionRegister = lazy(
  () => import("@/pages/AuctionCenter/AuctionRegister"),
);
const HostAuction = lazy(() => import("@/pages/AuctionCenter/HostAuction"));
const AuctionHistory = lazy(
  () => import("@/pages/AuctionCenter/AuctionHistory"),
);
const Collab = lazy(
  () => import("@/pages/AuctionCenter/Collab"),
);
const ParticularBidHistory = lazy(
  () => import("@/pages/AuctionCenter/ParticularBidHistory"),
);
const ParticularAuctionAnalytics = lazy(
  () => import("@/pages/AuctionCenter/ParticularAuctionAnalytics"),
);
const RequestedUsers = lazy(
  () => import("@/components/HomePage/RequestedUsers"),
);
const AuctionRegistration = lazy(
  () => import("@/pages/AuctionCenter/AuctionRegistration"),
);
const BidRecords = lazy(() => import("@/pages/AuctionCenter/BidRecords"));
const AddRegistrations = lazy(
  () => import("@/pages/AuctionCenter/AddRegistrations"),
);
const BiddingHome = lazy(() => import("@/pages/Home/BiddingHome"));

const auctionRoutes = [
  { path: "/center", element: <AuctionCenter /> },
  { path: "/host", element: <HostAuction /> },
  { path: "/auction-history/:id", element: <AuctionHistory /> },
  { path: "/collab/:id", element: <Collab/> },
  { path: "/auction-register/:id", element: <AuctionRegister /> },
  { path: "/center/bids/:id", element: <ParticularBidHistory /> },
  { path: "/home-bidding/:id", element: <BiddingHome /> },
  { path: "/auction-analytics/:id", element: <ParticularAuctionAnalytics /> },
  { path: "/home-bidding/notallowed/:id", element: <RequestedUsers /> },

  { path: "/auction-registrations/:id", element: <AuctionRegistration /> },
  { path: "/bid-records/:id", element: <BidRecords /> },
  { path: "/add-registration/:id", element: <AddRegistrations /> },
];

export default auctionRoutes;
