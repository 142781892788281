import React, { useState, useEffect } from 'react';
import * as Slider from '@radix-ui/react-slider';
import "./slider.css";

const DiscreteSlider = ({ charValues, coinValues, onCoinChange, onCharChange, currentTextLength }) => {
  const [selectedCoin, setSelectedCoin] = useState(coinValues[0]);

  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    if (currentTextLength <= charValues[newValue]) {
      setSelectedCoin(coinValues[newValue]);
      onCoinChange(coinValues[newValue]);
      onCharChange(charValues[newValue]);
    }
  };

  const adjustSliderForTextLength = () => {
    for (let i = 0; i < charValues.length; i++) {
      if (currentTextLength <= charValues[i]) {
        setSelectedCoin(coinValues[i]);
        onCoinChange(coinValues[i]);
        onCharChange(charValues[i]);
        break;
      }
    }
  };

  useEffect(() => {
    adjustSliderForTextLength();
  }, [currentTextLength]);

  return (
    <div className="w-full max-w-lg mx-auto">      
      <div className='flex flex-col items-center relative'>
        <input
        type="range"
        min="0"
        max={coinValues.length - 1}
        value={coinValues.indexOf(selectedCoin)}
        onChange={handleSliderChange}
        className="w-full h-4 bg-white appearance-none rounded mt-0"
        />
        
      <div className="flex justify-between absolute top-0 left-0 right-0 bottom-0 -px-0.5 pointer-events-none">
        {coinValues.map((_, index) => (
          <div
            key={index}
            className={` ${
              index === coinValues.indexOf(selectedCoin) ? 'w-5 h-5 border-2 p-2 rounded-full bg-white border-gray-700' : 'bg-white border-gray-700'
            }`}
          >
            <input
            className="w-full h-1 bg-gray-700 appearance-none rounded "
            />
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default DiscreteSlider;
