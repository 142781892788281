import React, { lazy } from "react";

// Lazy load the admin components
const AdminPage = lazy(() => import("@/pages/Admin/Admin"));
const AdminUsers = lazy(() => import("@/pages/Admin/Users"));
const TicketSection = lazy(() => import("@/pages/Admin/TicketSection"));
const AdminApprovedAuction = lazy(
  () => import("@/pages/Admin/ApprovedAuctionSection"),
);
const AdminAuctionCategory = lazy(
  () => import("@/pages/Admin/AuctionCatergory"),
);
const AdminAuctionDetail = lazy(() => import("@/pages/Admin/AuctionDetail"));
const AdminAuction = lazy(() => import("@/pages/Admin/AuctionSection"));
const ManageFAQs = lazy(() => import("@/pages/FAQs/ManageFAQs"));
const AdsBannerAdmin = lazy(() => import("@/pages/Admin/AdsBannerAdmin"));
const NobchatAdmin = lazy(() => import("@/pages/Admin/AdminNobchat"));
const LiveNobchatAdmin = lazy(() => import("@/pages/Admin/AdminLiveNobchat"));
const AdminKyc = lazy(() => import("@/pages/Kyc/AdminKyc"));
const AdminKycHome = lazy(() => import("@/pages/Kyc/AdminKycHome"));
const AdminConsultant = lazy(() => import("@/pages/Admin/Consultant"));
const AdminConsultantUser = lazy(() => import("@/pages/Admin/ConsultantUser"));
const AnnouncementAndBannerAdmin = lazy(
  () => import("@/pages/Admin/AnnouncementAdmin"),
);
const AdminProfileVerificationHome = lazy(
  () => import("@/pages/Admin/AdminProfileVerificationHome"),
);
const AdminProfileVerification = lazy(
  () => import("@/pages/Admin/AdminProfileVerification"),
);
const AdminChatSettlementHome = lazy(
  () => import("@/pages/Admin/AdminChatSettlementHome"),
);
const AdminChatSettlementLive = lazy(
  () => import("@/pages/Admin/AdminChatSettlementLive"),
);
const AdminChatSettlementPrivate = lazy(
  () => import("@/pages/Admin/AdminChatSettlementPrivate"),
);
const AdminAuctionSettlementHome = lazy(
  () => import("@/pages/Admin/AdminAuctionSettlementsHome"),
);
const AdminAuctionSettlements = lazy(
  () => import("@/pages/Admin/AdminAuctionSettlements"),
);
const AdminConsultationSettlements = lazy(
  () => import("@/pages/Admin/AdminConsultationSettlements"),
);
const AdminBrSettlements = lazy(
  () => import("@/pages/Admin/AdminBrSettlements"),
);
const PromotionSettlement = lazy(
  () => import("@/pages/Admin/PromotionConsultationDetails"),
);
const AppointmentSettlement = lazy(
  () => import("@/pages/Admin/AppointmentConsultationDetails"),
);
const Earnings = lazy(
  () => import("@/pages/Admin/Withdraw"),
);
const AdminCharges = lazy(() => import("@/pages/Admin/AdminCharges"));
const AdminData = lazy(() => import("@/pages/Admin/AdminData"));
const AdminBidsHome = lazy(() => import("@/pages/Admin/AdminBidsHome"));
const AdminBidsList = lazy(() => import("@/pages/Admin/AdminBidsList"));
const AdminBidsVerification = lazy(
  () => import("@/pages/Admin/AdminBidsVerification"),
);
const AdminAdManager = lazy(
  () => import("@/components/AdManager/AdminAdManager"),
);
const AdminAdManagerPage = lazy(
  () => import("@/pages/AdManager/AdminAdManager"),
);

const adminRoutes = [
  { path: "/admin", element: <AdminPage /> },
  { path: "/admin/users", element: <AdminUsers /> },
  { path: "/admin/charges", element: <AdminCharges /> },
  { path: "/admin/data", element: <AdminData /> },
  { path: "/admin/tickets", element: <TicketSection /> },
  { path: "/admin/announcement", element: <AnnouncementAndBannerAdmin /> },
  { path: "/admin/auctions/approved", element: <AdminApprovedAuction /> },
  { path: "/admin/auctions/category", element: <AdminAuctionCategory /> },
  { path: "/admin/auctions", element: <AdminAuction /> },
  { path: "/admin/auctions/:id", element: <AdminAuctionDetail /> },
  { path: "/admin/manage-faqs", element: <ManageFAQs /> },
  { path: "/admin/ads", element: <AdsBannerAdmin /> },
  { path: "/admin/chat", element: <NobchatAdmin /> },
  { path: "/admin/live-chat", element: <LiveNobchatAdmin /> },
  { path: "/admin/kyc", element: <AdminKyc /> },
  { path: "/admin/kyc-home", element: <AdminKycHome /> },
  { path: "/admin/socials", element: <AdminProfileVerificationHome /> },
  { path: "/admin/socials/:id", element: <AdminProfileVerification /> },
  { path: "/admin/settlement/chat", element: <AdminChatSettlementHome /> },
  { path: "/admin/bids", element: <AdminBidsHome /> },
  { path: "/admin/bids/auction/:id", element: <AdminBidsList /> },
  { path: "/admin/bids/:id", element: <AdminBidsVerification /> },
  {
    path: "/admin/settlement/chat/live/:id",
    element: <AdminChatSettlementLive />,
  },
  {
    path: "/admin/settlement/chat/private/:id",
    element: <AdminChatSettlementPrivate />,
  },
  { path: "/admin/consultant", element: <AdminConsultant /> },
  { path: "/admin/consultant/:id", element: <AdminConsultantUser /> },
  {
    path: "/admin/settlement/auctions",
    element: <AdminAuctionSettlementHome />,
  },
  {
    path: "/admin/settlement/auction/bids/:id",
    element: <AdminAuctionSettlements />,
  },
  {
    path: "/admin/settlement/adminConsultationSettlements",
    element: <AdminConsultationSettlements />,
  },
  { path: "/admin/settlement/auction/br/:id", element: <AdminBrSettlements /> },
  { path: "/admin/settlement/promotion/:id", element: <PromotionSettlement /> },
  { path: "/admin/settlement/appointment/:id", element: <AppointmentSettlement /> },
  { path: "/admin/settlement/earnings", element: <Earnings /> },
  { path: "/admin/ad-manager", element: <AdminAdManager /> },
  { path: "/admin/ad-manager-details", element: <AdminAdManagerPage /> },
];

export default adminRoutes;
