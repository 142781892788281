import { useNavigate } from "react-router-dom";
import clipText from "@/lib/getClipText";
import { RiAuctionFill, RiAuctionLine, RiCloseFill } from "react-icons/ri";
import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { IoClose } from "react-icons/io5";
import axiosInstance from "@/api/axios";
import useAuth from "@/redux/dispatch/useAuth";

const BiddersCard = ({ b, uid, aid }) => {
  const navigate = useNavigate();
  const [auctionQty, setAuctionQty] = useState(0);

  // const getAuctionDetails = async () => {
  //   try {
  //     const res = await axiosInstance.get(`/auctions/${aid}`);
  //     setAuctionQty(res.data.Quantity);
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Error in getting auction details");
  //   }
  // };

  // useEffect(() => {
  //   getAuctionDetails();
  // }, []);
  function amt(val) {
    
    if (val > 0 && val<100000) {
      return `₹ ${Number(val).toLocaleString("en-IN")}`;
    } else if (val>=100000 &&val<10000000) {
      return `₹ ${Number((val/100000).toFixed(4)).toLocaleString("en-IN")} L`;
    } else if (val>=10000000) {
      return `₹ ${Number((val/10000000).toFixed(4)).toLocaleString("en-IN")} Cr`;
    } 
  }
  function timeAgo(createdAt) {
    var createdDate = new Date(createdAt);
    var currentDate = new Date();
    var difference = currentDate.getTime() - createdDate.getTime();
    var seconds = Math.floor(difference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var weeks = Math.floor(days / 7);
    var months = Math.floor(days / 30);
    var years = Math.floor(days / 365);
    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} sec${seconds > 1 ? "s" : ""} ago`;
    }
  }
  const t = timeAgo(b.createdAt);
  return (
    <div
      className={` flex flex-row items-center text-gray justify-between px-1  
        ${b.Status === "successful" ? "border border-gray-950 text-green-800 rounded-sm":""}
        ${b.Status === "Partially successful" ? "border border-gray-950 text-green-800 rounded-sm":""}
        ${b.Status === "canceled" ? "text-red-800":""}
        ${uid === b.userID ? "rounded-none bg-gray-100  text-gray-800 mt-0.5 " : "border-t border-gray-300 mt-0.5 rounded-none"} 
        ${b?.auctionType === "offline" ? "py-1" : ""}`}
    >
      {/* {console.log("bids")} */}
      <div className="w-30 flex flex-col items-start pb-1 pl-0.5 pt-0.5">
        {b?.BidderId === "random" ? (
          <div
            className={` ${b.Status !== "unsuccessful" ? "text-md font-bold" : "font-medium text-zinc-700"}`}
          >
            {b?.BidderRegistrationId}
          </div>
        ) : b.regType !== "host" ? (
          <div
            className={`cursor-pointer ${b.Status !== "unsuccessful" ? "text-md font-bold" : "font-medium text-zinc-700"}`}
            onClick={() => {
              navigate(`/profile/${b.userName}`);
            }}
          >
            {clipText(b.userName,15)}
          </div>
        ) : (
          <div
            className={` ${b.Status !== "unsuccessful" ? "text-md font-bold" : "font-medium text-zinc-700"}`}
          >
            {clipText(b.userName,15)}
          </div>
        )}

        {b?.auctionType !== "offline" && (
          <div
            className={`${b.Status !== "unsuccessful" ? "" : "text-zinc-700"} text-[0.7rem] flex flex-row gap-3`}
          >
            {t} <li>Quantity: {b.Quantity}</li>
          </div>
        )}
      </div>
      {/* {auctionQty !== 1 && b?.auctionType !== "offline" && (
        <div className="flex flex-col items-center text-center">
          <div
            className={` ${b.Status !== "unsuccessful" ? "font-bold" : "font-medium text-zinc-700"}`}
          >
            {b.Quantity}{" "}
          </div>
          <div
            className={` ${b.Status !== "unsuccessful" ? "" : "font-medium text-zinc-700"} text-[0.7rem]`}
          >
            Quantity{" "}
          </div>
        </div>
      )} */}
      <div>
        {b.Status === "successful" ? <div className="flex items-center gap-1"><RiAuctionFill className="text-lg" /></div> : ""}
        {b.Status === "Partially successful" ? (
          <div className="flex items-center gap-1"><RiAuctionLine className="text-lg" /> </div>
        ) : (
          ""
        )}
        {b.Status === "canceled" ? <RiCloseFill className="text-lg" /> : ""}
      </div>
      <div className="flex flex-col items-center pr-0.5">
        <div
          className={` ${b.Status !== "unsuccessful" ? "text-md font-bold" : "font-medium text-zinc-700"}`}
        >
           {amt(b.bidAmount)}
        </div>
        <div className="text-[0.6rem]">
          {/* {console.log(uid,"uid",b.userID)} */}
          {uid === b.userID ? b.BidId : ""}
        </div>
      </div>
    </div>
  );
};

export default BiddersCard;
