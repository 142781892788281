import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaPlay } from "react-icons/fa";
import { ref, getMetadata } from "firebase/storage";
import { storage } from "@/firbaseConfig.js";
import Modal from "react-modal";
import { useSwipeable } from "react-swipeable";

// Metadata cache
const metadataCache = new Map();

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState("");
  const [selectedMediaType, setSelectedMediaType] = useState("");
  const videoRef = useRef(null);
  const modalVideoRef = useRef(null);
  useEffect(() => {
    const fetchAllMetadata = async () => {
      const types = await Promise.all(
        images.map(async (url) => {
          if (metadataCache.has(url)) {
            return metadataCache.get(url);
          }
          try {
            const storageRef = ref(storage, url);
            const metadata = await getMetadata(storageRef);
            const contentType = metadata.contentType;
            metadataCache.set(url, contentType);
            return contentType;
          } catch (error) {
            console.error("Failed to fetch metadata for", url, error);
            return null; 
          }
        }),
      );
      setMediaTypes(types);
    };

    fetchAllMetadata();
  }, [images]);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const isVideo = (contentType) =>
    contentType && contentType.startsWith("video/");

  const openModal = (url, type) => {
    setSelectedMediaUrl(url);
    setSelectedMediaType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMediaUrl("");
    setSelectedMediaType("");
  };

  const syncVideoState = () => {
    if (videoRef.current && modalVideoRef.current) {
      modalVideoRef.current.currentTime = videoRef.current.currentTime;
      if (!videoRef.current.paused) {
        modalVideoRef.current.play();
      } else {
        modalVideoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    if (isModalOpen && selectedMediaType.startsWith("video/")) {
      syncVideoState();
    }
  }, [isModalOpen]);

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="relative mx-auto w-full max-w-full ">
      <div
        {...handlers}
        className="relative overflow-hidden  bg-white"
      >
        {images.map((url, index) => (
          <div
            key={index}
            className={`transition-opacity duration-300 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
            style={{
              position: index === currentIndex ? "relative" : "absolute",
              width: "100%",
            }}
          >
            {mediaTypes[index] && isVideo(mediaTypes[index]) ? (
              <div className="relative">
                <video
                  ref={index === currentIndex ? videoRef : null}
                  src={url}
                  className="h-full w-full border-y object-cover"
                  style={{ aspectRatio: "1 / 1" }}
                  muted
                  playsInline
                  preload="metadata"
                  controls={false}
                />
                <button
                  onClick={() => openModal(url, mediaTypes[index])}
                  className="absolute inset-0 flex items-center justify-center bg-opacity-50 px-4 py-2 text-xs text-white shadow-xl sm:text-base"
                >
                  <FaPlay className="h-6 w-6" />
                </button>
              </div>
            ) : (
              <img
                src={url}
                alt={`Auction Media ${index + 1}`}
                className="h-full w-full cursor-pointer border-y object-cover"
                style={{ aspectRatio: "1 / 1" }}
                onClick={() => openModal(url, mediaTypes[index])}
              />
            )}
          </div>
        ))}
      </div>
      {images.length > 1 && (
        <>
          <button
            className="absolute -left-0 top-1/2 -translate-y-1/2 transform ml-0.5 rounded-full bg-gray-100 p-1 text-zinc-700 opacity-75 hover:bg-gray-300 sm:p-1 sm:text-xs"
            onClick={prevSlide}
          >
            <FaArrowLeft className="h-4 w-4 sm:h-4 sm:w-4" />
          </button>
          <button
            className="absolute -right-0 top-1/2 -translate-y-1/2 transform mr-0.5 rounded-full bg-gray-100 p-1 text-zinc-700 opacity-75 hover:bg-gray-300 sm:p-1 sm:text-xs"
            onClick={nextSlide}
          >
            <FaArrowRight className="h-4 w-4 sm:h-4 sm:w-4" />
          </button>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Media Modal"
        className="flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
      >
        <div className="relative flex w-fit items-center justify-center rounded-md p-4 shadow-lg sm:h-[70vh] sm:w-[70vw] md:h-[70vh] md:w-[60vw] lg:h-[70vh] lg:w-[75vw] xl:h-[70vh] xl:w-fit">
          <button
            onClick={closeModal}
            className="bg-black-900 absolute -right-0 -top-0 h-8 w-8 rounded-full text-2xl text-white"
          >
            &times;
          </button>
          {selectedMediaType.startsWith("video/") ? (
            <video
              ref={modalVideoRef}
              src={selectedMediaUrl}
              controls
              muted
              autoPlay
              className="h-full w-fit rounded-md object-contain p-2"
            />
          ) : (
            <img
              src={selectedMediaUrl}
              alt="Selected Media"
              className="h-full w-full rounded-md object-contain p-2"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Carousel;
