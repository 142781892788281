import useAuth from "@/redux/dispatch/useAuth";
import React, { useEffect, useState } from "react";
import { getChatHistoryOfUser } from "@/api/consultationApi";
import { User2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

function ConsultationChatBox() {
  const {
    auth: { user },
  } = useAuth();
  const [chatHistory, setChatHistory] = useState([]);
  const navigator = useNavigate();

  useEffect(() => {
    getChatHistoryOfUser(user._id)
      .then((res) => {
        setChatHistory(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="h-full p-4">
      <div className="mb-4 flex justify-between border-b border-black pb-2 pl-2 text-xl font-bold">
        <span>Consultation Chats</span>
        {/* <span> Your Response Rate: {}</span> */}
      </div>

      <ul className="mt-4 flex flex-col-reverse overflow-y-auto">
        {chatHistory.map((chat, index) => (
          <li
            className={`flex items-center justify-between gap-4 rounded-md p-2 py-4 hover:cursor-pointer ${
              index % 2 === 0 ? "bg-gray-100" : "bg-white"
            }`}
            key={chat.roomId}
            onClick={() =>
              navigator(`/consultation/chat/${chat.user.userName}`)
            }
          >
            <div className="flex items-center gap-4">
              {chat.user.profileImage ? (
                <img
                  src={chat.user.profileImage}
                  alt="Profile"
                  className="h-10 w-10 rounded-full object-cover"
                />
              ) : (
                <User2 className="h-10 w-10 rounded-full border border-black bg-white p-2" />
              )}
              <p className="text-lg font-semibold">{chat.user.userName}</p>
            </div>

            <div>
              {chat.permitStatus === "requested" ? (
                <span className="rounded-md bg-yellow-500 px-2 py-1 text-xs text-white">
                  Requested
                </span>
              ) : chat.permitStatus === "accepted" ? (
                <span className="rounded-md bg-green-500 px-2 py-1 text-xs text-white">
                  Accepted
                </span>
              ) : (
                <span className="rounded-md bg-red-500 px-2 py-1 text-xs text-white">
                  Rejected
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ConsultationChatBox;
