import axiosInstance from '@/api/axios';

export const fetchKYCStatusAndType = async (userId) => {
    try {
      const response = await axiosInstance.get(`/user/getKYCStatusAndType/${userId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching KYC status and type:", error);
      throw error;
    }
  };
  
  export const updateKYCStatusAndType = async (id, kycType, kycStatus) => {
    try {
    
      const response = await axiosInstance.post('/user/updateKYCStatusAndType', {
        id,
        kycType,
        kycStatus
      });
      return response.data;
    } catch (error) {
      console.error('Error updating KYC status and type:', error);
      throw error;
    }
  };